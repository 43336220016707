function Download({ fill = "black" }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      alt="download"
    >
      <path
        d="M8 10L7.57574 10.4243L8 10.8485L8.42426 10.4243L8 10ZM8.6 1C8.6 0.668629 8.33137 0.4 8 0.4C7.66863 0.4 7.4 0.668629 7.4 1L8.6 1ZM2.57574 5.42426L7.57574 10.4243L8.42426 9.57574L3.42426 4.57574L2.57574 5.42426ZM8.42426 10.4243L13.4243 5.42426L12.5757 4.57574L7.57574 9.57574L8.42426 10.4243ZM8.6 10L8.6 1L7.4 1L7.4 10L8.6 10Z"
        fill={fill}
      />
      <path
        d="M1 12L1 13C1 14.1046 1.89543 15 3 15L13 15C14.1046 15 15 14.1046 15 13V12"
        stroke={fill}
        strokeWidth="1.2"
      />
    </svg>
  );
}

export default Download;
