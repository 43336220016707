import ChildItem from "../ChildItem";

/*  
    This component will represent the first order of the heirarchy. 

    In this example:
    ParentItem == Admin 0 (Country level)
    ChildItem == Admin 1 (State/territory level)
    GrandChildItem == Admin 2 (City/county level) 
*/
const ParentItem = ({ filteredData, handleSelectItem, handleKeyDown }) => {
  const hoverStyle =
    "search-item hover:bg-gray focus:bg-gray hover:rounded focus:rounded";
  const topLevelStyle = `p-2 border-b border-gray-300 cursor-pointer`;
  const textStyle = "font-semibold pl-1";

  return (
    <>
      {filteredData.map((item) => (
        <li key={item.id} className={topLevelStyle} tabIndex={0}>
          <div
            className={`${textStyle} text-lg ${hoverStyle}`}
            data-displaytext={`${item.name}`}
            tabIndex={1}
            onClick={(e) => {
              e.stopPropagation();
              handleSelectItem(item.admin, item.id);
            }}
            onKeyDown={(e) => {
              handleKeyDown(e, `${item.name}`, item.admin, item.id);
            }}
            aria-label={item.name}
            role="button"
          >
            {item.name}
          </div>
          {item.children && item.children.length > 0 && (
            <ChildItem
              data={item}
              handleSelectItem={handleSelectItem}
              handleKeyDown={handleKeyDown}
              displayText={`${item.name}`}
              label={"States"}
              textStyle={`${textStyle} text-md ${hoverStyle}`}
            />
          )}
        </li>
      ))}
    </>
  );
};

export default ParentItem;
