// these export functions will help determine if a user clicks a new admin 0 or 1 region (country)
export function getAdmin0Code(gid) {
  return gid.substring(0, 3);
}

export function isSameAdmin0(g1, g2) {
  return getAdmin0Code(g1) === getAdmin0Code(g2);
}

export function getAdmin1Code(gid) {
  // we need to handle GHA differently bc it is missing a period
  // from the official database we get the data from
  // until that is fixed, we need to handle it this way
  if (gid.includes("GHA")) {
    return `${gid.split(".")[0]}.${gid.split(".")[1]}`;
  } else {
    return `${gid.split(".")[0]}.${gid.split(".")[1]}_1`;
  }
}
