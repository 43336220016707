import { useState } from "react";
import Circle from "../MetricsPanel/PanelContents/components/Legend/components/Circle";
import clsx from "clsx";

export default function Toggle({
  setIsEnabled,
  label,
  labelLeft = true,
  circle = false,
  checked = false,
}) {
  const [isChecked, setIsChecked] = useState(checked);

  const changeToggle = (e) => {
    e.stopPropagation();
    setIsChecked(!isChecked);
    setIsEnabled(!isChecked);
  };

  const Label = () => {
    return (
      <div className="inline-flex items-center">
        <div className={clsx("text-md", labelLeft ? "mr-2" : "ml-2")}>
          {label}
        </div>
        {circle && <Circle backgroundColor={circle} />}
      </div>
    );
  };

  return (
    <label className={parentContainerStyle}>
      {labelLeft && <Label />}

      <input
        type="checkbox"
        value=""
        className="sr-only peer"
        checked={isChecked}
        onChange={changeToggle}
      />
      <div className={toggleBtnStyle}></div>
      {!labelLeft && <Label />}
    </label>
  );
}

const parentContainerStyle = clsx(
  "inline-flex justify-between items-center w-full cursor-pointer"
);
const toggleBtnStyle = clsx(
  "customToggle relative w-11 h-6 bg-black peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-transparent dark:peer-focus:ring-transparent rounded-full peer dark:bg-black-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-black peer-checked:after:bg-yellowButton after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-black"
);

// WHAT PROPS TO PASS INTO COMP
//
// const toggleProps = {
//    setIsEnabled: () => {},
//    label: `Compare with Admin Level ${adminLevel}`,
//    labelLeft: false
// }
