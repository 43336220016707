import isNumberInRange from "./isNumberInRange";

export default function calculateBaselineData(
  chartData,
  baselineData,
  range,
  units
) {
  // Create a copy of the existing baselineData for the given id
  const updatedAreas = { ...baselineData };

  chartData.forEach((area) => {
    // Check if the area name exists in baselineData
    if (!updatedAreas[area.name]) {
      return; // Skip if the area doesn't exist in baselineData
    }

    if (area.values.length === 0) {
      return; // Skip if the area doesn't have any values, will use initial baselineData
    }
    
    let totalDeforestationRate = 0;
    let totalValue = 0;
    let count = 0;

    area.values.forEach((value) => {
      if (isNumberInRange(value.year, range)) {
        totalDeforestationRate += value[units];
        totalValue += value[units];
        count += 1;
      }
    });

    const avgDeforestationRate = totalDeforestationRate / count;
    updatedAreas[area.name] = {
      avg_deforestation_rate: avgDeforestationRate.toFixed(2),
      value: totalValue.toFixed(2),
      unit: units,
    };
  });

  return updatedAreas;
}
