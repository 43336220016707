import clsx from "clsx";

function Tooltip({ data, x, y, visible, year }) {
  // left and top only work when defined as a div style
  const tooltipStyle = {
    left: x,
    top: y,
    opacity: visible ? 1 : 0,
  };

  return (
    <div style={tooltipStyle} className={tooltipContainerStyle}>
      <div className="px-2 bg-white">
        <div className={`${textStyle}`}>{year}</div>
      </div>
      {data.map((item, index) => (
        <div key={index} className="px-2 flex items-center gap-1">
          <div className="flex flex-row gap-1">
            <div className={`${textStyle} font-bold`}>{item.value}</div>
            <div className={unitStyle}>{item.unit}</div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Tooltip;

const tooltipContainerStyle = clsx(
  `absolute py-1 bg-white flex flex-col items-start gap-1 rounded-md`
);
const textStyle = clsx("opacity-90 text-black text-xs font-mono");
const unitStyle = clsx(textStyle, " text-neutral-500 font-light text-xs");
