import { useEffect } from "react";
import mapboxgl from "mapbox-gl";
import { useCurrentMap } from "../../../../contexts/MapProvider";

export default function Scale() {
  const { currentMap } = useCurrentMap();

  useEffect(() => {
    currentMap.scaleControlRef.current = new mapboxgl.ScaleControl();
    currentMap.mapRef.current.addControl(currentMap.scaleControlRef.current);

    if (currentMap && currentMap.scaleControlRef.current) {
      const scaleControl = currentMap.scaleControlRef.current._container;
      const attribControl = document.querySelector(".mapboxgl-ctrl");

      if (scaleControl && attribControl) {
        scaleControl.style.position = "absolute";
        scaleControl.style.right = `${attribControl.offsetWidth - 160}px`; // 10px spacing
        scaleControl.style.border = "2px solid white";
      }
    }

    return () => {
        if (currentMap.mapRef) {
          if (currentMap.scaleControlRef.current && currentMap.mapRef.removeControl) {
            currentMap.mapRef.removeControl(currentMap.scaleControlRef.current);
          }
        }
      };
  }, [currentMap]);
}
