import clsx from "clsx";

const positionBase = clsx("lg:hidden flex absolute");
const topBase = clsx(" mt-[70px]");
const whiteLine = clsx(" bg-[white] w-[2px]");

// MOBILE
export const zoom = clsx(
  positionBase + " right-0 flex-col items-end mr-[18px] mt-[90px]"
);
export const method = clsx(
  positionBase + " right-0 bottom-0 mb-[195px] mr-10 items-center"
);

const searchTablet = clsx("md:items-center md:w-full");
export const search = clsx(
  positionBase +
    topBase +
    " flex-col text-center mr-12 md:-ml-6 " +
    searchTablet
);
export const layerParent = clsx(
  positionBase + topBase + " flex-col items-end -mr-16 " + searchTablet
);
export const layerWrapper = clsx("flex flex-col items-end md:ml-[194px]");
export const halfLayer = clsx("flex flex-col items-center");
export const layerLine = clsx(whiteLine + " h-28 -mt-[6px]");
export const layerText = clsx("max-w-[160px] -mt-6 mr-3 text-center");

export const metricsParent = clsx(positionBase + " bottom-20 mb-14 flex-col md:w-full md:items-center");
export const metricsVerticalContainer = clsx("ml-6 flex items-start flex-col");
export const metricsLine = clsx(whiteLine + " h-28 -mb-[6px]");

// TABLET
