export default function getMinValueFromArray(arr) {
  let minValue = Infinity;

  for (let i = 0; i < arr.length; i++) {
    if (arr[i] < minValue) {
      minValue = arr[i];
    }
  }

  return minValue;
}
