import clsx from "clsx";

function SearchOptions({ children, handleMouseOver, handleMouseOut }) {
  return (
    <ul
      id="search-dropdown"
      className={searchDropdownStyle}
      onMouseOver={() => handleMouseOver()}
      onMouseOut={() => handleMouseOut()}
    >
      {children}
    </ul>
  );
}

export default SearchOptions;

const searchDropdownStyle = clsx(
  "border mt-2 rounded-lg bg-white max-h-96 overflow-y-auto"
);
