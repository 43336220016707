import { ArrowUpperRightIcon, CalendarIcon } from "../../../../../Icons";
import * as styles from "./styles";
import { useCurrentModal } from "../../../../../../contexts";

function EventsPopUp({ title, subtitle, desc, articleUrl, state }) {
  const { currentModal } = useCurrentModal();
  const handleUrlClick = (event) => {
    event.stopPropagation();
    const newWindow = window.open(articleUrl, "_blank");

    if (newWindow) {
      newWindow.focus();
    } else {
      alert("Popup blocked! Please allow popups for this website.");
    }
  };

  const handleCloseClick = (e) => {
    currentModal.closeModal(e);
  };

  return (
    <>
      <div className={styles.popUpTitleContainer}>
        <div>
          <div className={styles.popUpTitleText}>{title}</div>
          {subtitle && (
            <div className={styles.popUpSubTitleText}>
              <CalendarIcon />
              <div className="text-md text-black">{subtitle}</div>
            </div>
          )}
        </div>
        {state === "PUBLISHED" && (
          <div className={styles.popUpCloseContainer}>
            <button
              alt="Open article in new window button"
              onClick={handleUrlClick}
            >
              <span className="sr-only">Open Article</span>
              <ArrowUpperRightIcon />
            </button>
          </div>
        )}
      </div>
      <div className={styles.popUpContentContainer}>{desc}</div>
      <div className={styles.popUpCloseTextContainer}>
        <button onClick={handleCloseClick} className={styles.closeButtonText}>
          Close
        </button>
      </div>
    </>
  );
}

export default EventsPopUp;
