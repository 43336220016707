import clsx from "clsx";

// mobile styles
const mobile = clsx("lg:hidden w-full bg-black transition-all duration-300")
export const mobileStaticContainer = clsx(mobile + " absolute bottom-0 left-0 inline-block z-[60] overflow-hidden ease-in-out")
export const mobileStaticBody = clsx(mobile + " fixed h-5/6 overflow-y-auto")
export const panelTitleMobile = clsx(
  `w-full inline-flex justify-between items-center p-4 bg-black text-white text-xl font-semibold`
);
export const mobileTitle = clsx(
  "flex w-full justify-between items-center"
);

// desktop styles
export const panelDesktopContainer = clsx(
  `scrollStyle hidden lg:block max-h-[95%] absolute z-10 mt-4 ml-4 rounded-md w-[410px] overflow-y-auto overflow-x-hidden`
);