import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { CloseXIcon } from "../Icons";

function ModalComponent({ title, desc, isOpen, onRequestClose, children }) {
  const modalRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState("100%");

  const handleKeyDown = (event) => {
    event.stopPropagation();
    if (event.keyCode === 27) {
      onRequestClose(event);
    }
  };

  const handleClose = (event) => {
    event.stopPropagation();
    onRequestClose(event);
  };

  const handleContentClick = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    const updateMaxHeight = () => {
      if (window.innerWidth >= 768) {
        setMaxHeight("60%"); // 60% height for md screens and above
      } else {
        setMaxHeight("100%"); // full height for smaller screens
      }
    };

    updateMaxHeight();
    window.addEventListener("resize", updateMaxHeight);

    return () => {
      window.removeEventListener("resize", updateMaxHeight);
    };
  }, []);

  useEffect(() => {
    if (isOpen && modalRef.current) {
      modalRef.current.focus();
    }
  }, [isOpen]);
  return (
    isOpen && (
      <div
        className={modalAndOverlayContainer}
        onClick={handleClose}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        ref={modalRef}
      >
        <div
          className={modalContainer}
          onClick={handleContentClick}
          style={{ maxHeight, height: 'auto' }}
        >
          {title ? <div className={modalTitleContainer}>
            <div className="w-3/4">
              <div className="text-xl font-semibold text-black">{title}</div>
              {desc && <div className="text-md text-black">{desc}</div>}
            </div>
            <div className="w-1/4 flex justify-end items-end">
              <button alt="Close button" onClick={handleClose}>
                <span className="sr-only">Close</span>
                <CloseXIcon />
              </button>
            </div>
          </div> : null}
          {React.isValidElement(children) ? (
            children
          ) : (
            <div
              className={modalContentContainer}
              dangerouslySetInnerHTML={{ __html: children }}
            />
          )}
        </div>
      </div>
    )
  );
}

const modalAndOverlayContainer = clsx(
  "fixed inset-0 z-[70] h-full w-full flex items-center justify-center backdrop-blur-sm bg-black/50"
);
const modalContainer = clsx(
  "z-10 h-auto w-full md:w-1/2 rounded-lg bg-white p-6 shadow-lg overflow-y-auto"
);
const modalTitleContainer = clsx(
  "flex items-start justify-between border-b border-slate-300 bg-white pb-4"
);
const modalContentContainer = clsx("flex flex-col w-full h-auto py-4");

export default ModalComponent;