export default function getRangeFromValues(values) {
  const years = values
    .map((item) => Number(item.year))
    .filter((year) => !isNaN(year));

  if (years.length === 0) {
    return [0, 0]; // Default values if no valid years are found
  }

  const minYear = Math.min(...years);
  const maxYear = Math.max(...years);

  return [minYear, maxYear];
}
