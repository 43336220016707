import { useEffect, useRef } from "react";
import clsx from "clsx";
import { ExpandArrowIcon } from "../Icons";

export default function Dropdown({
  title = null,
  icon = null, // pass an svg icon to have the dropdown display an icon instead of text
  isOpen,
  setIsOpen,
}) {
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    // The dropdown title and contents are separate elements.
    // This check ensures the dropdown only closes when the user clicks outside
    // of the dropdown, avoiding glitchy closure if the title or toggle is clicked.
    if (
      event.target.classList.contains("dropdownTitleElement") ||
      event.target.classList.contains("customToggle")
    ) {
      return;
    }
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const handleDropdownClick = (event) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  return (
    <div
      ref={dropdownRef}
      className={icon ? "" : dropdownParentStyle}
      onClick={handleDropdownClick}
    >
      {icon ? (
        icon
      ) : (
        <div className="dropdownTitleElement font-bold text-lg">{title}</div>
      )}
      {title ? (
        <ExpandArrowIcon
          className="dropdownTitleElement"
          rotate={isOpen}
          alt={isOpen ? "close subsection" : "open subsection"}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

const dropdownParentStyle = clsx(
  "dropdownTitleElement flex justify-between items-center relative z-10 w-full bg-white p-4 rounded-lg cursor-pointer border-[1px] border-black/20"
);
