/*  
    This component will represent the third order of the heirarchy. 
*/
const GrandChildItem = ({
  data,
  handleSelectItem,
  handleKeyDown,
  displayText,
  textStyle,
}) => {
  return (
    <ul className="ml-8 text-xs">
      {data.map((grandchild) => (
        <li key={grandchild.id} tabIndex={0}>
          <div
            className={textStyle}
            data-displaytext={`${displayText}, ${grandchild.name}`}
            tabIndex={2}
            onClick={(e) => {
              e.stopPropagation();
              handleSelectItem(
                grandchild.admin,
                grandchild.id
              );
            }}
            onKeyDown={(e) => {
              handleKeyDown(
                e,
                `${displayText}, ${grandchild.name}`,
                grandchild.admin,
                grandchild.id
              );
            }}
            aria-label={grandchild.name}
            role="button"
          >
            {grandchild.name}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default GrandChildItem;
