import * as constants from './constants'

export default function togglePointsVisibility(map, isHidden) {
  const layers = [constants.outer, constants.inner, constants.clickable];

  layers.forEach((layer) => {
    if (!isHidden) {
      map.setLayoutProperty(layer, "visibility", "none");
    } else {
      map.setLayoutProperty(layer, "visibility", "visible");
    }
  });
}
