import { scaleLinear } from "d3-scale";

const d3 = {
  scaleLinear,
};

export default function getXScale(xAxisLimits, chartWidth) {
  const [startYear, endYear] = xAxisLimits;
  return d3
    .scaleLinear()
    .domain([startYear, endYear + 1]) // Set domain to cover the range of years
    .range([0, chartWidth]); // Map domain to the width of the SVG
}
