function Information({ fill = "black", width = 3, height = 11 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 3 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.07386 11L1.07386 3.36364H2.24716L2.24716 11H1.07386ZM1.67045 2.09091C1.44176 2.09091 1.24455 2.01302 1.07884 1.85724C0.91643 1.70147 0.835227 1.5142 0.835227 1.29545C0.835227 1.0767 0.91643 0.889441 1.07884 0.733664C1.24455 0.577888 1.44176 0.5 1.67045 0.5C1.89915 0.5 2.0947 0.577888 2.2571 0.733664C2.42282 0.889441 2.50568 1.0767 2.50568 1.29545C2.50568 1.5142 2.42282 1.70147 2.2571 1.85724C2.0947 2.01302 1.89915 2.09091 1.67045 2.09091Z"
        fill={fill}
      />
    </svg>
  );
}

export default Information;
