import clsx from "clsx";
import { useState } from "react";
import { ExpandArrowIcon } from "../../../../Icons";

function ExpandableSection({ title, children }) {
  const [expanded, setExpanded] = useState(false);

  const toggleSection = () => {
    setExpanded(!expanded);
  };
  return (
    <div className={sectionStyle}>
      <div
        className={toggleStyle}
        onClick={toggleSection}
      >
        <div className="inline-flex">
          <div className="font-bold text-lg">{title}</div>
        </div>
        <button onClick={toggleSection}>
          <ExpandArrowIcon rotate={expanded} alt={expanded ? "close subsection" : "open subsection"} />
        </button>
      </div>
      <div className={`content ${expanded ? expandedStyle : collapsedStyle}`}>
        {children}
      </div>
    </div>
  );
}

const sectionStyle = clsx(
  "w-full bg-white lg:px-4 py-4 lg:py-0 max-w-[410px] m-auto my-1 rounded-lg"
);
const toggleStyle = clsx(
  "flex justify-between items-center cursor-pointer"
);
const expandedStyle = clsx(
  "opacity-100 max-h-auto transition-max-height duration-500 ease-in-out"
);
const collapsedStyle = clsx(
  "opacity-0 max-h-0 overflow-hidden transition-max-height duration-500 ease-in-out"
);

export default ExpandableSection;
