import clsx from "clsx";
import { InformationIcon, ReportAnIssueIcon } from "../../../../Icons";
import Button from "../../../../Button";
import DownloadData from "../DownloadData";
import ReportAnIssue from "../../../../ReportAnIssue";
import * as constants from "../../../../../constants";
import * as context from "../../../../../contexts";

export default function BottomButtons({ product }) {
  const { currentModal } = context.useCurrentModal();
  const { currentSelection } = context.useCurrentSelection();

  const reportAnIssueProps = {
    onClick: () => {
      currentModal.openModal(
        "Report An Issue",
        "",
        <ReportAnIssue />
      );
    },
    backgroundColor: "blackButton",
    roundedSize: "rounded-full",
  };

  return (
    <div className={buttonsNearChart}>
      <div className="flex gap-2 w-full">
        <DownloadData product={currentSelection.selectedLayer.product} gid={currentSelection.gid} />
        <Button
          backgroundColor={"whiteButton"}
          border={"border border-black"}
          onClick={() => {
            currentModal.openModal(
              constants.panelConfig[product].infoTooltip.title,
              "",
              constants.panelConfig[product].infoTooltip.content
            );
          }}
        >
          <InformationIcon />
        </Button>
        <Button
          {...reportAnIssueProps}
        >
          <ReportAnIssueIcon fill={"yellowButton"} />
        </Button>
      </div>
    </div>
  );
}

const buttonsNearChart = clsx(
  "flex lg:w-full lg:justify-start lg:items-start max-w-[410px] m-auto lg:mx-0"
);
