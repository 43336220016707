import clsx from "clsx";

// RIGHT SIDE
const rightBase = clsx("hidden lg:flex absolute right-0 text-center flex");
export const search = clsx(rightBase + " mr-4 mt-[70px] flex-col");
export const zoom = clsx(rightBase + " mr-6 items-center flex-row-reverse");
export const question = clsx(
  rightBase + " bottom-0 mb-14 mr-12 flex-row-reverse"
);

// LEFT SIDE
const leftBase = clsx("hidden lg:flex absolute left-0 flex");
export const layerDropdown = clsx(leftBase + " mt-12 ml-[380px]");
export const timeline = clsx(leftBase + " mt-36 ml-[380px]");
export const download = clsx(leftBase + " top-[600px] ml-[350px]");