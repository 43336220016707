function CloseX({ fill = "black" }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      alt="close modal"
    >
      <path
        d="M1.00011 1L15.0001 15M15 1L1 15"
        stroke={fill}
        strokeWidth="1.5"
      />
    </svg>
  );
}

export default CloseX;
