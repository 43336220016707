import { BaselineStat } from "../Charts/components";
import { useCurrentChart } from "../../../../../contexts";

export default function ChartSection() {
  const { currentChart } = useCurrentChart();

  // Check if the category aligns with the baselineData
  const alignedData = currentChart.category && currentChart.baselineInfo;

  return (
    <div className={chartSectionContainer}>
      <div className="w-full mx-auto -mb-2">{currentChart.chartSectionProps.chart}</div>
      {/* Render only if data aligns */}
      {alignedData ? (
        generateBaselineStats(
          currentChart.baselineData,
          currentChart.baselineInfo
        )
      ) : (
        <div className="text-xs text-center text-red-500">
          Data not available for this category
        </div>
      )}
      {/* <div className="text-xs text-center">{description}</div> */}
    </div>
  );
}

const generateBaselineStats = (baseline, config) => {
  if (!baseline) {
    return;
  }

  const stats = [];

  config.forEach((element) => {
    const key = element.key;
    const title = element.title;
    const value = element.value;

    stats.push(
      <BaselineStat
        key={title}
        title={title}
        unit={baseline[key]?.unit ? baseline[key]?.unit : ""}
        value={baseline[key]?.[value] ? baseline[key]?.[value] : 0}
      />
    );
  });

  return stats;
};

const chartSectionContainer = "flex flex-col justify-center items-center mb-4 lg:max-w-[410px]";