import { createContext, useContext, useState, useRef } from "react";

const MapContext = createContext();

export const MapProvider = ({ children }) => {
  // mapbox states and references
  const [mapLoaded, setMapLoaded] = useState(false);
  const mapRef = useRef(null);
  const scaleControlRef = useRef(null);

  const currentMap = {
    mapLoaded,
    setMapLoaded,
    mapRef,
    scaleControlRef,
  };

  return (
    <MapContext.Provider value={{ currentMap }}>
      {children}
    </MapContext.Provider>
  );
};

export const useCurrentMap = () => {
  const context = useContext(MapContext);

  if (context === undefined) {
    throw new Error(
      "useCurrentMap must be used within a MapProvider"
    );
  }

  return context;
};
