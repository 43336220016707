import { useRef, useEffect, useState } from "react";
import Footer from "../Footer";
import * as styles from "./styles";

function Body({ options, isOpen, selected, handleItemClick }) {
  const contentRef = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (contentRef.current) {
      setHeight(isOpen ? contentRef.current.scrollHeight : 0);
    }
  }, [isOpen]);

  return (
    <div
      ref={contentRef}
      className={`content relative w-full z-50 ${
        isOpen ? styles.menuOpenCommon : styles.menuClosed
      }`}
      style={{ height: `${height}px` }}
    >
      {options?.map((layer) => {
        const selectedItem = selected.displayName === layer.displayName;
        const onItemClick = selectedItem ? null : () => handleItemClick(layer);

        return (
          <div
            key={layer.displayName}
            className={
              selectedItem ? styles.selectedItem : styles.dropdownItem
            }
            onClick={onItemClick}
          >
            {layer.displayName}{" "}
            {layer.info && (
              <div className={styles.dropdownItemInfo}>{layer.info}</div>
            )}
          </div>
        );
      })}
      <div className={styles.divider}></div>
      <div className={styles.dropdownToggleItem}><Footer /></div>
    </div>
  );
}

export default Body;

/*

Options must have displayName:
const options = [
  {
    displayName: "Historical Deforestation",
    info: "(1990-2023|30m)",
    serverCall:
      "https://tileserver-biomass-2022.s3.us-west-2.amazonaws.com/{z}/{x}/{y}.png",
  },
  ... ]

*/
