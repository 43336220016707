import { useState, useEffect, useRef } from "react";
import SearchInput from "./components/Input";
import ParentItem from "./components/ParentItem";
import Skeleton from "react-loading-skeleton";
import ctreesLogo from "../../assets/ctrees-sm-logo.png";
import SearchOptions from "./components/SearchOptions";
import { useCurrentSearch } from "../../contexts/SearchProvider";
import { getAdminNameTree } from "../Mapbox/helpers/endpoints";
import * as styles from "./styles";

export default function SearchContainer() {
  const { currentSearch } = useCurrentSearch();
  const searchContainerRef = useRef(null);

  const [searchData, setSearchData] = useState([]); // all search items
  const [searchDataLoaded, setSearchDataLoaded] = useState(false); // search items are loaded

  function handleKeyDown(event, item) {
    if (event.key === "ArrowDown" || event.key === "ArrowUp") {
      event.preventDefault();
      navigateResults(event.key);

      // Disable scrolling during arrow key navigation
      document.getElementById("search-dropdown").style.overflowY = "hidden";
    }
  }

  function navigateResults(key) {
    const resultItems = document.querySelectorAll(".search-item");
    let currInx = currentSearch.currentIndex;

    if (key === "ArrowDown" && currInx < resultItems.length - 1) {
      currInx = currInx + 1;
      currentSearch.setCurrentIndex(currInx);
    } else if (key === "ArrowUp" && currInx > 0) {
      currInx = currInx - 1;
      currentSearch.setCurrentIndex(currInx);
    }

    if (resultItems[currInx]) {
      resultItems[currInx].focus();
    }
  }

  const handleKeyDownDropdown = (e, newDisplayText, adminLevel, gid) => {
    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      e.preventDefault();
      navigateResults(e.key);

      // Disable scrolling during arrow key navigation
      document.getElementById("search-dropdown").style.overflowY = "hidden";
    }
    if (e.key === "Enter" || e.key === " ") {
      e.stopPropagation();
      currentSearch.handleSelectItem(adminLevel, gid);
    }
  };

  function handleMouseOver() {
    // Enable scrolling when the mouse is over the resultsContainer
    document.getElementById("search-dropdown").style.overflowY = "auto";
  }

  function handleMouseOut() {
    // Disable scrolling when the mouse leaves the resultsContainer
    document.getElementById("search-dropdown").style.overflowY = "hidden";
  }

  // filter on every keydown
  const filterInHierarchy = (items, searchTerm) => {
    if (!items) {
      return [];
    }

    if (!Array.isArray(items)) {
      return items;
    }

    return items
      .map((item) => ({
        ...item,
        similarityScore: calculateSimilarityScore(item, searchTerm),
        children: filterInHierarchy(item.children, searchTerm),
        grandchildren: filterInHierarchy(
          item.children && item.children.children
            ? item.children.children
            : undefined,
          searchTerm
        ),
      }))
      .sort((a, b) => b.similarityScore - a.similarityScore) // Sort by similarity score, most similar items up top
      .filter((item) => item.similarityScore > 0);
  };

  // determines how similar the search term is to the item being searched
  const calculateSimilarityScore = (item, searchTerm) => {
    const name = item.name
      .toLowerCase()
      .normalize("NFD")
      .replace(/\p{Diacritic}/gu, "");
    const searchTermLower = searchTerm
      .toLowerCase()
      .normalize("NFD")
      .replace(/\p{Diacritic}/gu, "");

    // prioritize similarity for different admin levels
    const adminLevelBuffer =
      item.admin === 0 ? 3 : item.admin === 1 ? 2 : item.admin === 2 ? 1 : 0;

    if (name === searchTermLower) {
      return 3 + adminLevelBuffer; // Exact match at any level
    } else if (name.startsWith(searchTermLower)) {
      return 2 + adminLevelBuffer; // Partial match at the beginning of the name
    } else if (item.children && item.children.length > 0) {
      // Check similarity of any children
      const childSimilarityScores = item.children.map((child) =>
        calculateSimilarityScore(child, searchTerm)
      );
      const maxChildScore = Math.max(...childSimilarityScores);
      if (maxChildScore > 0) {
        return maxChildScore - 1; // Reduce score slightly to prioritize direct matches
      }
    } else if (
      item.children &&
      item.children.children &&
      item.chilren.children.length > 0
    ) {
      // Check similarity of any grandchildren
      const grandChildSimilarityScores = item.children.children.map(
        (grandchild) => calculateSimilarityScore(grandchild, searchTerm)
      );
      const maxGrandChildScore = Math.max(...grandChildSimilarityScores);
      if (maxGrandChildScore > 0) {
        return maxGrandChildScore - 2; // Reduce score further to prioritize direct and child matches
      }
    }
    return 0; // No match
  };

  const updatedFilteredData = filterInHierarchy(
    searchData,
    currentSearch.searchTerm
  );
  const noFilteredDataInSearch = updatedFilteredData.length && searchData;

  useEffect(() => {
    const fetchSearchData = async () => {
      try {
        const data = await getAdminNameTree();

        if (data) {
          // Set the data to the state or context for rendering
          setSearchData(data);
          setSearchDataLoaded(true);
        } else {
          console.error("Failed to fetch data.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchSearchData();
  }, []);

  useEffect(() => {
    if (searchDataLoaded) {
      currentSearch.setHasFilteredData(true);
    }
  }, [searchDataLoaded]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        currentSearch.setSearchTerm("");
        currentSearch.setIsOpen(false);
      }
    }

    // close search dropdown when user clicks outside of the search container
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchContainerRef]);

  return (
    <div className={styles.searchParentContainer} ref={searchContainerRef}>
      <div className="w-full flex gap-5 items-center">
        <div className="w-full flex items-center gap-2">
          <a className="" href="https://ctrees.org" target="_blank">
            <img
              className="lg:block md:block hidden w-[130px]"
              src={ctreesLogo}
            />
          </a>
          <div className="text-black text-[10px] leading-[11px]">
            SELECTED <br />
            AREA
          </div>
          <div className="relative flex items-center w-full">
            <SearchInput
              setIsOpen={currentSearch.setIsOpen}
              handleKeyDown={handleKeyDown}
              inputStyle={styles.input}
            />
            {currentSearch.isOpen && currentSearch.searchTerm && (
              <div className={styles.dropdownBodyContainer}>
                {!searchDataLoaded ? (
                  <div className={styles.searchDropdownBody}>
                    <Skeleton className="h-[50px] mb-2" count={6} />
                  </div>
                ) : (
                  <div>
                    {noFilteredDataInSearch ? (
                      <SearchOptions
                        handleMouseOver={handleMouseOver}
                        handleMouseOut={handleMouseOut}
                      >
                        <ParentItem
                          filteredData={updatedFilteredData}
                          handleKeyDown={handleKeyDownDropdown}
                          handleSelectItem={currentSearch.handleSelectItem}
                        />
                      </SearchOptions>
                    ) : (
                      <div className={"p-2 text-center"}>
                        <p>
                          The region you're searching for is not included in our
                          dataset.
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
