export default function ReferenceLine({ width = 449, half = false }) {
  const hundredthValue = Math.round(width / 100);
  const minus10 = width - 10;
  const minus9 = minus10 + 1;

  return (
    <svg
      width={width}
      height="12"
      viewBox={`0 0 ${width} 12`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {half ? (
        /* Half: displays a line with only ONE ARROW at the end*/
        <path
          d={`M10 5L5.04736e-07 0.226497L-5.04736e-07 11.7735L10 7L10 5ZM${minus10} 7.0000${hundredthValue}L${width} 7.0000${hundredthValue}ZM9 7L${minus9} 7.0000${hundredthValue}L${minus9} 5.0000${hundredthValue}L9 5L9 7Z`}
          fill="white"
        />
      ) : (
        /* Standard: displays a line with AN ARROW ON EACH end */
        <path
          d={`M10 5L5.04736e-07 0.226497L-5.04736e-07 11.7735L10 7L10 5ZM${minus10} 7.0000${hundredthValue}L${width} 11.7735L${width} 0.226537L${minus10} 5.0000${hundredthValue}L${minus10} 7.0000${hundredthValue}ZM9 7L${minus9} 7.0000${hundredthValue}L${minus9} 5.0000${hundredthValue}L9 5L9 7Z`}
          fill="white"
        />
      )}
    </svg>
  );
}
