import clsx from "clsx";

export const menuOpenCommon = "border-[1px] border-black/20 bg-white rounded-lg transition-all duration-500 ease-in-out overflow-hidden";
export const menuClosed = "overflow-hidden rounded-lg transition-all duration-500 ease-in-out";

export const dropdownItem = clsx(
  "flex justify-between items-center hover:bg-yellowButtonHover py-2 px-4 first:rounded-t-lg last:rounded-b-lg cursor-pointer"
);
export const selectedItem = clsx(
  dropdownItem + " text-black hover:bg-yellowButtonDefault bg-yellowButtonDefault"
);
export const dropdownItemInfo = clsx(
  "flex items-end text-sm font-light text-darkgrey"
);
export const divider = clsx("h-[2px] bg-gray/50 w-full my-2");
export const dropdownToggleItem = clsx(
  "w-full py-2 px-4 mb-2 last:rounded-b-lg"
);
