import { useState } from "react";
import clsx from "clsx";
import { ExpandArrowIcon } from "../Icons";
import * as styles from "./styles";

export default function MetricsPanel({ children }) {
  const [isDrawerOpen, setIsPanelOpen] = useState(false);

  const toggleDrawer = () => {
    setIsPanelOpen(!isDrawerOpen);
  };

  const panelMobileContainer = clsx(
    styles.mobileStaticContainer + ` ${isDrawerOpen ? "h-[88%]" : "h-auto"}`
  );
  const panelBodyMobile = clsx(
    styles.mobileStaticBody +
      ` ${isDrawerOpen ? "translate-y-0" : "h-0 translate-y-full"}`
  );

  const titleContainerStyle = clsx(styles.panelTitleMobile + (isDrawerOpen ? "" : " mb-20"))
  return (
    <>
      <div
        className={styles.panelDesktopContainer}
        style={{ scrollbarGutter: "stable" }}
      >
        {children}
      </div>
      <div className={panelMobileContainer}>
        <input
          type="checkbox"
          id="drawer-toggle"
          className="hidden relative sr-only"
          defaultChecked={isDrawerOpen}
        />
        <div className={titleContainerStyle} onClick={toggleDrawer}>
          <div className={styles.mobileTitle}>
            <div>Metrics</div>{" "}
            <ExpandArrowIcon
              fill="white"
              rotate={isDrawerOpen}
              alt={isDrawerOpen ? "close metrics" : "open metrics"}
            />
          </div>
        </div>
        <div className={panelBodyMobile}>
          <div className="lg:hidden bg-white p-4">{children}</div>
        </div>
      </div>
    </>
  );
}
