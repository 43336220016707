import { createContext, useContext, useState } from "react";

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  // modal states and functions
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState(null);
  const [modalDesc, setModalDesc] = useState(null);
  const [modalContent, setModalContent] = useState(null);

  const closeModal = (event) => {
    event.stopPropagation();
    setModalIsOpen(false);
    setModalContent(null);
    setModalTitle(null);
    setModalDesc(null);
  };
  const openModal = (title, desc, content = true) => {
    setModalIsOpen(true);
    setModalTitle(title);
    setModalDesc(desc);
    setModalContent(content);
  };

  const currentModal = {
    modalTitle,
    modalDesc,
    modalContent,
    modalIsOpen,
    closeModal,
    openModal,
  };

  return (
    <ModalContext.Provider value={{ currentModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useCurrentModal = () => {
  const context = useContext(ModalContext);

  if (context === undefined) {
    throw new Error("useCurrentModal must be used within a ModalProvider");
  }

  return context;
};
