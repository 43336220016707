function Calendar({fill = "black"}) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1927 15.5H3.04828C1.91705 15.5 1 14.583 1 13.4517V3.30732C1 2.17609 1.91705 1.25903 3.04828 1.25903H13.1927C14.3239 1.25903 15.241 2.17609 15.241 3.30732V13.4517C15.241 14.583 14.3239 15.5 13.1927 15.5Z"
        stroke={fill}
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 6.00806H15.241"
        stroke={fill}
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
      <path
        d="M8.12048 0.5V2.01806"
        stroke={fill}
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.4944 0.5V2.01806"
        stroke={fill}
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.75635 0.5V2.01806"
        stroke={fill}
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default Calendar;
