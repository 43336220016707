export default function Layers({ fill = "black", className }) {
  return (
    <svg
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      alt="layer selector"
      className={className}
    >
      <path
        d="M11.9935 15.6161L2.51132 10.2014L0.428467 11.391L11.9999 18L23.5713 11.391L21.4756 10.1967L11.9935 15.6161ZM11.9999 13.2179L21.4692 7.81275L23.5713 6.60897L11.9999 0L0.428467 6.60897L2.52418 7.8033L11.9999 13.2179Z"
        fill={fill}
        className={className}
      />
    </svg>
  );
}
