import clsx from "clsx";

function Button({
  onClick,
  backgroundColor = "white",
  textColor = "black",
  width = 8, // set as 'auto' for buttons with text
  roundedSize = "rounded-full",
  border = "border-none",
  className,
  children,
}) {
  const buttonContainer = clsx(
    className +
      ` h-${width} ${
        width !== "auto" ? `w-${width}` : `min-w-${width}` // allow button widths to suit buttons with text
      } cursor-pointer flex items-center justify-center`,
    { "p-3": width == "auto" }, // extra padding for buttons with text
    `bg-${backgroundColor}Default hover:bg-${backgroundColor}Hover clicked:bg-${backgroundColor}Click text-${textColor} ${border} text-sm ${roundedSize}`
  );

  return (
    <div className="pointer-events-auto z-40">
      <button className={buttonContainer} onClick={onClick}>
        {children}
      </button>
    </div>
  );
}

export default Button;
