function QuestionMark({ fill = "black" }) {
  return (
    <svg
      width="12"
      height="20"
      viewBox="0 0 12 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      alt="more information"
    >
      <path
        d="M5.99902 3.52206e-07C4.40821 0.00158878 2.88301 0.63424 1.75814 1.75911C0.633263 2.88399 0.000611868 4.40919 -0.000976562 6H1.99902C1.99902 4.93913 2.42045 3.92172 3.1706 3.17157C3.92074 2.42143 4.93816 2 5.99902 2C6.95979 1.99906 7.88881 2.34396 8.6162 2.97164C9.34359 3.59932 9.82075 4.46784 9.96045 5.4184C10.1001 6.36896 9.89303 7.33805 9.37699 8.14846C8.86094 8.95888 8.07044 9.55648 7.15002 9.832C6.52926 10.0126 5.98393 10.3899 5.59602 10.9071C5.20812 11.4243 4.99862 12.0535 4.99902 12.7V16H6.99902V12.7C7.00091 12.4841 7.07265 12.2745 7.20352 12.1027C7.33438 11.931 7.51734 11.8062 7.72502 11.747C9.10481 11.3331 10.2896 10.4366 11.063 9.2213C11.8364 8.00599 12.1468 6.553 11.9373 5.12778C11.7279 3.70256 11.0128 2.40025 9.92246 1.45877C8.83217 0.517296 7.43955 -0.000492986 5.99902 3.52206e-07Z"
        fill={fill}
      />
      <path d="M6.99902 18H4.99902V20H6.99902V18Z" fill="black" />
    </svg>
  );
}

export default QuestionMark;
