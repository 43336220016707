import { ReferenceLineIcon } from "../../../../../Icons";
import * as constants from "../constants";
import * as styles from "./styles";

export default function Desktop() {
  return (
    <>
      {/* SEARCH BAR INFO */}
      <div className={styles.search}>
        {/* MOBILE */}
        <div className="md:hidden">
          <ReferenceLineIcon width={300} />
        </div>
        {/* TABLET */}
        <div className="hidden md:flex lg:hidden">
          <ReferenceLineIcon width={420} />
        </div>
        <div className="max-w-[300px] md:max-w-[380px]">{constants.search}</div>
      </div>

      <div className={styles.layerParent}>
        <div className={styles.layerWrapper}>
          <div className={styles.halfLayer}>
            <ReferenceLineIcon width={34} />
            <div className={styles.layerLine}></div>
          </div>
          <div className="flex mr-2 -mt-[7px]">
            <div className={styles.layerText}>
              Change data layers, consult legend key
            </div>
            <ReferenceLineIcon width={100} half={true} />
          </div>
        </div>
      </div>

      {/* ZOOM INFO */}
      <div className={styles.zoom} style={{ top: "calc(40% - 140px)" }}>
        <div>{constants.zoom}</div>
        <ReferenceLineIcon width={30} />
      </div>

      {/* QUESTION MARK INFO */}
      <div className={styles.method}>
        <div className="-mr-[8px]">{constants.method}</div>
        <div className="rotate-90">
          <ReferenceLineIcon width={30} />
        </div>
      </div>

      {/* DOWNLOAD + LEARN INFO + REPORT ISSUE */}
      <div className={styles.metricsParent}>
        <div className={styles.metricsVerticalContainer}>
          <div className="flex items-center -mb-[25px]">
            <div className="rotate-180 -ml-2 mr-2">
              <ReferenceLineIcon width={60} half={true} />
            </div>
            <div className="flex flex-col">
              <div>Discover statistics and datasets</div>
              <div>{constants.report}</div>
            </div>
          </div>
          <div className={styles.metricsLine}></div>
        </div>
        {/* MOBILE */}
        <div className="md:hidden">
          <ReferenceLineIcon width={330} />
        </div>
        {/* TABLET */}
        <div className="hidden md:flex lg:hidden">
          <ReferenceLineIcon width={600} />
        </div>
      </div>
    </>
  );
}
