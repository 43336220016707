import clsx from "clsx";

export const parentContainer = clsx(
  "flex items-center justify-center h-full w-full"
);
export const modalContainer = clsx(
  "md:w-[600px] w-[300px] h-auto bg-white rounded-md p-4 flex flex-col items-center"
);
export const header = clsx(
  "border-b-2 border-b-black/10 w-full mb-4 pb-4 md:text-2xl text-xl"
);
export const nextArrow = clsx(
  "cursor-pointer rounded-full bg-yellowButton px-8 py-4 my-4 hover:bg-yellowButtonHover"
);
export const skip = clsx("cursor-pointer underline text-black/40");

export const accept = clsx(
  "cursor-pointer rouded-full bg-yellowButton px-8 py-4 my-4 hover:bg-yellowButtonHover"
);
