import clsx from "clsx";
import * as components from "./components";

export default function StepTwo({ onClick }) {
  return (
    <>
      {/* GET STARTED BTN */}
      <div className={startBtnStyle} onClick={onClick}>
        Get started now
      </div>
      <div className={parentContainerStyle} onClick={onClick}>
        <components.Desktop />
        <components.Mobile />
      </div>
    </>
  );
}

const parentContainerStyle = clsx(
  "flex h-full w-full cursor-pointer text-[white] justify-center md:justify-start"
);

const startBtnStyle = clsx(
  "left-[30%] lg:left-[45%] md:left-[40%] md:py-4 py-2 px-4 md:px-8 md:text-md text-lg" +
    " absolute top-[45%] hover:bg-yellowButtonHover bg-yellowButton font-bold rounded-lg"
);
