import { getMaxValueFromArray, getMinValueFromArray } from "../../../helpers";

export default function addColorsToChartRange(chartRange) {
    const minRange = getMinValueFromArray(chartRange);
    const maxRange = getMaxValueFromArray(chartRange);
    const numberOfYears = maxRange - minRange + 1;
    
    return [
        numberOfYears > 4 && {hex: '#4B006C', tailwind: 'darkPurple', label: (minRange)?.toString()},
        numberOfYears > 3 && {hex: '#84186D', tailwind: 'magenta', label:`${minRange + 1}-${maxRange - 3}`},
        numberOfYears > 2 && {hex: '#C62B5B', tailwind: 'redPink', label: (maxRange - 2)?.toString()},
        {hex: '#F35F4A', tailwind: 'orange', label: (maxRange - 1)?.toString()},
        {hex: '#FCAC6D', tailwind: 'lightOrange', label: (maxRange)?.toString()}
    ]
}