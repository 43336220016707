import { InformationIcon } from "../Icons";
import Button from "../Button";
import clsx from "clsx";

export default function MapTitle({ title }) {
  const onClick = () => {
    // currentModal.openModal(
    //   questionMarkModal.title,
    //   "",
    //   questionMarkModal.contents
    // );
  };

  return (
    <div className={parentContainerStyle} onClick={onClick}>
      <div className={textStyle}>{title}</div>
{/*       <div className={buttonStyle}>
        <InformationIcon />
      </div> */}
    </div>
  );
}

const textStyle = clsx("text-black text-md flex items-center justify-center");
const buttonStyle = clsx(
  "flex items-center justify-center w-5 h-5 group-hover/btn:bg-whiteButtonHover rounded-full border border-black flex-col justify-center items-center flex"
);
const parentContainerStyle = clsx(
  "p-1.5 px-4 w-fit bg-yellowButton group/btn hover:bg-yellowButtonHover absolute mt-20 z-40 rounded-md inline-flex gap-4"
);
