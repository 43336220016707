import clsx from "clsx";

export const screen = clsx("relative flex flex-col h-screen w-full");

export const searchAndLayer = clsx(
  "flex justify-center lg:justify-end lg:pr-4 w-full relative lg:mb-auto"
);
export const layerButtonMobile = clsx(
  "lg:hidden flex items-center mt-6 ml-2 z-10"
);
export const dropdownContentsContainer = clsx(
  "lg:hidden w-full px-3 max-w-[390px] md:max-w-[520px] absolute mt-20"
);
export const buttonBottomRight = clsx(
  "absolute bottom-48 lg:bottom-14 right-4 z-10 lg:block space-y-2.5"
);
