import { useEffect, useRef } from "react";
import { useCurrentSelection, useCurrentSearch } from "../../../../contexts";

const SearchInput = ({ handleKeyDown, inputStyle }) => {
  const { currentSelection } = useCurrentSelection();
  const { currentSearch } = useCurrentSearch();
  const inputRef = useRef(null);

  // user typing
  const handleInputChange = (value) => {
    currentSearch.setSearchTerm(value);
    currentSearch.setIsDisplayText(false);
    currentSearch.setFilteredData(currentSearch.filteredData);
    currentSearch.setIsOpen(true);

    // empty search
    if (value.trim() === "") {
      currentSearch.handleResetSearchTerm();
    }
  };

  // user clears input
  const handleInputBlur = () => {
    if (
      currentSearch.searchTerm.trim() === "" &&
      currentSelection.displayText.trim() === ""
    ) {
      currentSearch.handleResetSearchTerm();
    } else {
      currentSearch.setIsDisplayText(true);
    }
  };

  // start typing or go back to what user was typing before selecting item
  const handleSingleClick = () => {
    currentSearch.setIsDisplayText(false); // will help go back to inputing (original search term)

    // Select all text in the input field
    if (inputRef.current) {
      inputRef.current.select();
    }
  };

  // selects all text in the input for user for a new search
  const handleDoubleClick = () => {
    // Select all text in the input field
    if (inputRef.current) {
      inputRef.current.select();
    }
  };

  const inputProps = {
    type: "text",
    placeholder: "Search location...",
    value: currentSearch.isDisplayText
      ? currentSelection.displayText
      : currentSearch.searchTerm,

    onChange: (e) => handleInputChange(e.target.value),
    onClick: handleSingleClick,
    onDoubleClick: handleDoubleClick,
    onBlur: handleInputBlur,
    onKeyDown: handleKeyDown,
    ref: inputRef,
    className: inputStyle,
  };

  return <input {...inputProps} />;
};

export default SearchInput;
