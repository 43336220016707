import * as styles from "./styles";
import * as constants from "./constants";

export default function StepOne({ setStep }) {
  return (
    <div className={styles.parentContainer}>
      <div className={styles.modalContainer}>
        <div className={styles.header}>{constants.title}</div>
        <div className="text-left overflow-y-scroll max-h-[300px]">
          <div className="my-2">{constants.pg1}</div>
          <div className="my-2">{constants.pg2}</div>
          <div className="my-2 text-xs">
            {constants.licenseAgreement}{" "}
            <a
              className="border-b-2 italic text-black/40"
              href="https://planet.widen.net/s/zfdpf8qxwk/participantlicenseagreement_nicfi_2024"
              target="_blank"
              rel="noreferrer"
            >
              Participant License Agreement
            </a>{" "}
            associated with NICFI.
          </div>
        </div>
        <div onClick={() => setStep(1)} className={styles.accept}>
          Accept
        </div>
        {/* <div onClick={onClick} className={styles.skip}>
          skip
        </div> */}
      </div>
    </div>
  );
}
