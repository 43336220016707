export default function ExpandArrow({
  fill = "black",
  rotate = false,
  alt = "open/close arrow",
  className,
}) {
  return (
    <svg
      width="26"
      height="12"
      viewBox="0 0 26 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      alt={alt}
      className={rotate ? `${className} rotate-180` : className}
    >
      <path d="M24 2L13 10L2 2" stroke={fill} strokeWidth="3" />
    </svg>
  );
}
