import { min } from "d3-array";
import { height } from "../../constants";
import { scaleLinear } from "d3-scale";

const d3 = {
    scaleLinear,
};

export default function getYScale(minYValue, maxYValue) {
    const range = maxYValue - minYValue;
    const adjustedPadding = range * 0.05; // Adjust padding based on 5% of the range
    const domainMin = minYValue - adjustedPadding;
    const domainMax = maxYValue + adjustedPadding;

    return d3
        .scaleLinear()
        .domain([
            domainMin, // lower bound
            domainMax, // upper bound
        ])
        .nice()
        .range([height, 0]);
}
