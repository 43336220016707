import clsx from "clsx";

export const panel = clsx("z-80 w-full");
export const subsection = clsx(
  "w-full lg:rounded-md bg-white lg:bg-none lg:px-4 lg:py-3 lg:my-2"
);
export const chartSection = clsx("flex flex-col");
export const hideOnMobile = clsx("hidden lg:block");
export const hideOnDesktop = clsx("block lg:hidden");
export const divider = clsx("h-[2px] bg-gray/50 w-full my-3");
export const downloadDataBottom = clsx("flex justify-center items-center mt-12 lg:mt-0 lg:block");