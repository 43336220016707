export default async function removeSourceLayer(map, layer, source) {
  if (map.getLayer(layer)) {
    await map.removeLayer(layer);

    const withBorder = layer + "-border";
    if (map.getLayer(withBorder)) {
      await map.removeLayer(withBorder);
    }
  }

  if (map.getSource(source) && layer !== "selected-outline") {
    await map.removeSource(source);
  }

  return;
}
