import { select } from "d3-selection";
import { line } from "d3-shape";
import { transition } from "d3-transition";
import { margin, width } from "../../constants";

const d3 = {
  select,
  line,
  transition,
};

export default function generateLines(
  svg,
  multiLineData,
  xScale,
  yScale,
  numXAxisValues,
  unit,
  lineStyles,
  chartWidth
) {
  // Calculate the offset based on the number of years being charted
  const xOffset = (chartWidth - margin.right) / numXAxisValues / 2;
  return multiLineData.forEach((series, seriesIndex) => {
    const lineGenerator = d3
      .line()
      .x((d) => xScale(d.year) + xOffset + margin.left)
      .y((d) => yScale(d[unit]));

    // Check if there is only one year in the data
    if (numXAxisValues === 1) {
      multiLineData.forEach((series, seriesIndex) => {
        series.values.forEach((dataPoint) => {
          // Calculate the y coordinate for the horizontal line
          const y = yScale(dataPoint[unit]);

          // Append a line element to the chart
          svg
            .append("line")
            .attr("x1", margin.left)
            .attr("x2", chartWidth + margin.left)
            .attr("y1", y)
            .attr("y2", y)
            .attr("stroke", lineStyles[seriesIndex].color || "black")
            .attr("stroke-width", 1.5)
            .attr("stroke-dasharray", lineStyles[seriesIndex].dasharray || "0")
            .attr("marker-end", lineStyles[seriesIndex].markerEnd || "");
        });
      });
    } else {
      // Multiple years selected
      svg
        .append("path")
        .datum(series.values)
        .attr("d", lineGenerator)
        .attr("stroke", lineStyles[seriesIndex].color || "black")
        .attr("stroke-width", 1.5)
        .attr("fill", "none")
        .attr("stroke-dasharray", lineStyles[seriesIndex].dasharray || "0")
        .attr("marker-end", lineStyles[seriesIndex].markerEnd || "")
        .transition()
        .duration(1000);
    }
  });
}
