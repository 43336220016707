import { useState } from "react";
import * as dropdown from "./../../Dropdown";
import * as components from "./components";
import * as styles from "./styles";
import * as constants from "../../../constants";
import { noDataText } from "./components/Charts/constants";
import { useCurrentSelection, ChartProvider } from "../../../contexts";

export default function PanelContents() {
  const { currentSelection } = useCurrentSelection();

  const product = currentSelection.selectedLayer.product;
  const chart = currentSelection.chartData;

  const [expandableSectionIsOpen, setExpandableSectionIsOpen] = useState(false);

  const dropdownProps = {
    title: currentSelection.selectedLayer.displayName,
    isOpen: currentSelection.layerDropdownIsOpen,
    setIsOpen: () =>
      currentSelection.setLayerDropdownIsOpen(
        !currentSelection.layerDropdownIsOpen
      ),
  };

  const dropdownContentsProps = {
    options: constants.layerOptions,
    isOpen: currentSelection.layerDropdownIsOpen,
    selected: currentSelection.selectedLayer,
    handleItemClick: currentSelection.changeLayer,
  };

  const expandableSectionProps = {
    title: `Totals for ${currentSelection.displayText}`,
    expandableIsOpen: expandableSectionIsOpen,
    setExpandableDropdown: () =>
      setExpandableSectionIsOpen(!expandableSectionIsOpen),
  };

  return (
    <div className={styles.panel}>
      <div className={styles.subsection}>
        {/* DESKTOP - DROPDOWN (SELECT)*/}
        <div className={styles.hideOnMobile}>
          <dropdown.Dropdown {...dropdownProps} />
        </div>

        <div className={styles.chartSection}>
          {/* DESKTOP - DROPDOWN (BODY)*/}
          <div className={styles.hideOnMobile}>
            <div className={styles.divider}></div>
            <dropdown.Body {...dropdownContentsProps} />
          </div>

          {product && chart ? (
            <ChartProvider product={product}>
              <>
                {/* MULTI RANGE SLIDER */}
                <components.MultiRangeSlider id={product} />
                {/* SLIDER AND CHART LEGEND ITEMS */}
                <components.Legend />

                {/* Chart Title - Based on Admin Level (state: displayText) */}
                <components.HeaderText />
                {/* LINE CHART */}
                <components.ChartSection />

                {/* INFORMATION and DOWNLOAD DATA FROM CHART */}
                <components.BottomButtons product={product} />
              </>
            </ChartProvider>
          ) : (
            <div className="px-4">{noDataText}</div>
          )}
        </div>
      </div>

      <div className={styles.hideOnDesktop}>
        {/* DIVIDER */}
        <div className={styles.divider}></div>
      </div>

      {product && chart && (
        <div className={styles.subsection}>
          <components.ExpandableSection {...expandableSectionProps}>
            <components.AggregateSummary
              data={currentSelection.aggSummaryData}
            />
          </components.ExpandableSection>
        </div>
      )}

      <div className={styles.hideOnDesktop}>
        {/* DIVIDER */}
        <div className={styles.divider}></div>
      </div>
    </div>
  );
}
