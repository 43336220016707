import { height, margin } from "../../constants";
import { initialAreaUnit } from "../../../../helpers/getUnitsForSingleChart";
import { formatNumber } from "../../helpers";

const calculateTooltipPosition = (
  x,
  y,
  chartWidth,
  chartHeight,
  tooltipWidth,
  tooltipHeight
) => {
  const offsetX = 10;
  const offsetY = 10;

  const tooltipX =
    x + offsetX + tooltipWidth > chartWidth
      ? x - tooltipWidth + offsetX
      : x + offsetX;
  const tooltipY =
    y + offsetY + tooltipHeight > chartHeight
      ? y - tooltipHeight + offsetY
      : y + offsetY;
  return { tooltipX, tooltipY };
};

export default function addTooltipFunctionality(
  svg,
  lines,
  xScale,
  yScale,
  setTooltip,
  unit,
  numXAxisValues,
  chartWidth,
  chartHeight
) {
  // Add the vertical line that intercepts the data points
  const verticalLine = svg
    .append("line")
    .attr("class", "vertical-line")
    .attr("x1", -1)
    .attr("x2", -1)
    .attr("y1", 0)
    .attr("y2", height)
    .attr("stroke", "transparent") // Set the initial color to transparent
    .attr("stroke-width", 1)
    .style("stroke-dasharray", "3, 3");

  // get the min value of the y-axis
  const minY = yScale.domain()[0];

  // Calculate the offset based on the number of years being charted
  const xOffset = (chartWidth - margin.right) / numXAxisValues / 2;
  const getXValue = (d) => {
    return xScale(d.year) + xOffset + margin.left;
  };

  // Add rect, vertical line, and circles at each data point for each line
  lines.forEach((series, seriesIndex) => {
    const handleMouseOver = (event, d) => {
      const x = getXValue(d);

      verticalLine
        .attr("x1", x)
        .attr("x2", x)
        .attr("y1", 0)
        .attr("y2", height)
        .attr("stroke", "#D2D8E3");

      // Change the opacity of all circles on the x-axis on hover
      svg
        .selectAll(".circle")
        .filter((circleData) => circleData.year === d.year)
        .attr("fill-opacity", 1);

      // Set the state of the tooltip
      const tooltipWidth = 140;
      const tooltipHeight = 60;
      const y = yScale(d[unit]);

      const getTooltipValue = (point, unit) => {
        const newUnit = minY < 1 ? initialAreaUnit : unit;
        let value = point ? formatNumber(point[newUnit], newUnit) : undefined;

        return { value, unit: newUnit };
      };

      const { tooltipX, tooltipY } = calculateTooltipPosition(
        x,
        y,
        chartWidth,
        chartHeight,
        tooltipWidth,
        tooltipHeight
      );

      // Update the tooltip position
      setTooltip({
        visible: true,
        year: d.year,
        data: lines.map((line) => {
          const point = line.values.find((point) => point.year === d.year);
          const valueAndUnit = getTooltipValue(point, unit);

          return {
            name: line.name,
            value: valueAndUnit.value,
            unit: valueAndUnit.unit,
          };
        }),
        x: tooltipX,
        y: tooltipY,
      });
    };

    const handleMouseOut = (event, d) => {
      verticalLine.attr("stroke", "transparent");

      // Change the opacity of all circles on the x-axis on hover
      svg
        .selectAll(".circle")
        .filter((circleData) => circleData.year === d.year)
        .attr("fill-opacity", 0);
      // Hide the tooltip on mouseout
      setTooltip({
        visible: false,
        data: [],
        x: 0,
        y: 0,
      });
    };

    // Add a hover rect for each data point (year)
    const tooltipHoverOffset = 10;
    svg
      .selectAll(`.hover-rect-${seriesIndex}`)
      .data(series.values)
      .enter()
      .append("rect")
      .attr("class", "hover-rect")
      .attr("x", (d) => {
        const x = getXValue(d) - tooltipHoverOffset;
        return x;
      })
      .attr("y", 0)
      .attr("width", 20)
      .attr("height", height)
      .attr("fill", "transparent")
      .on("mouseover", handleMouseOver)
      .on("mouseout", handleMouseOut);

    // Add transparent circles over each point
    svg
      .selectAll(`.circle-${seriesIndex}`)
      .data(series.values)
      .enter()
      .append("circle")
      .attr("class", "circle")
      .attr("cx", (d) => {
        const cx = getXValue(d);
        return cx;
      })
      .attr("cy", (d) => {
        const cy = yScale(d[unit]);
        return cy;
      })
      .attr("r", 5)
      .attr("fill", "black")
      .attr("fill-opacity", 0)
      .on("mouseover", handleMouseOver)
      .on("mouseout", handleMouseOut);
  });
}
