import clsx from "clsx";
import Circle from "./components/Circle";
import { addColorsToChartRange } from "../Charts/helpers";
import { roadsPlanet, treeCoverLossPlanet } from "../../../../../constants";
import * as context from "../../../../../contexts";

// the chart legend and map legend are not the same for every product
// this function helps decide how we will display the legend items
const getLegendItems = (chartRange, selectedLayer) => {
  const isLayerRoads = selectedLayer === roadsPlanet;
  const isLayerTreeCoverLoss = selectedLayer === treeCoverLossPlanet;
  const range = isLayerTreeCoverLoss
    ? [2017, 2023]
    : [Math.min(...chartRange), Math.max(...chartRange)];

  const legendItems = addColorsToChartRange(range);

  if (isLayerRoads) {
    const roadsLegendItems = {
      hex: "#d3d3d3",
      tailwind: "offWhite",
      label: "2016",
    };
    legendItems.unshift(roadsLegendItems);
  }

  return legendItems;
};

export default function Legend() {
  const { currentChart } = context.useCurrentChart();
  const { currentSelection } = context.useCurrentSelection();
  const chartRange = currentChart.chartRange;
  const legendItems = getLegendItems(
    chartRange,
    currentSelection.selectedLayer.product
  );

  return (
    <div className={parentWrapperStyle}>
      {Object.values(legendItems).map(
        (item) =>
          item && (
            <div key={item.label} className="flex items-center gap-2">
              <Circle backgroundColor={item.hex} />
              <div className={labelStyle}>{item.label}</div>
            </div>
          )
      )}
    </div>
  );
}

const parentWrapperStyle = clsx(
  "w-full max-w-[410px] pt-6 gap-3 flex flex-wrap mb-2 -mt-3 m-auto justify-center"
);
const labelStyle = clsx(
  "opacity-90 text-black text-sm leading-tight font-mono"
);
