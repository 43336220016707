import { createContext, useContext, useState, useEffect } from "react";
import { useCurrentSelection } from "../SelectionProvider";
const SearchContext = createContext();
export const SearchProvider = ({ children }) => {
  const { currentSelection } = useCurrentSelection();
  const displayText = currentSelection.displayText;

  const [currentIndex, setCurrentIndex] = useState(-1); // index when they use arrow keys to search
  const [isOpen, setIsOpen] = useState(false); // open/closes the filtered search terms
  const [searchTerm, setSearchTerm] = useState(""); // value user is inputing
  const [isDisplayText, setIsDisplayText] = useState(true); // determines if user is actively typing or there is a location to display
  const [filteredData, setFilteredData] = useState({}); // Initialize with all location data
  const [hasFilteredData, setHasFilteredData] = useState(false);

  // user selects item from search query
  const updateSelection = (adminLevel, gid) => {
    currentSelection.setSelection(gid, adminLevel);
    setCurrentIndex(-1);
  };

  // user selects item from search query
  const handleSelectItem = (gid, adminLevel) => {
    setIsOpen(false);
    setIsDisplayText(true);
    setSearchTerm("");
    updateSelection(gid, adminLevel);
  };

  const handleResetSearchTerm = () => {
    currentSearch.setSearchTerm(""); // Clear search term
    currentSearch.setIsDisplayText(false); // Reset to search again
  };

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (filteredData.length > 0) {
      setHasFilteredData(true);
    } else {
      setHasFilteredData(false);
    }
  }, [filteredData]);

  const currentSearch = {
    searchTerm,
    setSearchTerm,
    isDisplayText,
    setIsDisplayText,
    filteredData,
    setFilteredData,
    isOpen,
    setIsOpen,
    hasFilteredData,
    setHasFilteredData,
    handleSelectItem,
    handleResetSearchTerm,
    currentIndex,
    setCurrentIndex,
  };

  return (
    <SearchContext.Provider value={{ currentSearch }}>
      {children}
    </SearchContext.Provider>
  );
};

export const useCurrentSearch = () => {
  const context = useContext(SearchContext);

  if (context === undefined) {
    throw new Error("useCurrentSearch must be used within a SearchProvider");
  }

  return context;
};
