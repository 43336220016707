import { createContext, useContext, useState, useRef, useEffect } from "react";
import { endpoints, maps } from "../../components/Mapbox/helpers";
import { layerOptions } from "../../constants";
import { useCurrentMap } from "../MapProvider";
import { panelConfig } from "../../constants";

const SelectionContext = createContext();

export const SelectionProvider = ({ children }) => {
  const { currentMap } = useCurrentMap();

  // panel and map
  const chartData = null;
  const aggSummaryData = null;
  const baselineData = null;
  const gid = "BRA";
  const adminLevel = 0;
  const displayText = "Brazil";
  const gidRef = useRef(gid);

  // layer
  const selectedLayer = layerOptions[0];
  const [layerDropdownIsOpen, setLayerDropdownIsOpen] = useState(false);

  const [allMapData, setAllMapData] = useState({
    selectedLayer,
    gid,
    adminLevel,
    displayText,
    chartData,
    aggSummaryData,
    baselineData,
    baselineInfo: panelConfig[selectedLayer.product].baselineStats,
  });

  const changeLayer = (layer) => {
    fetchPanelData(layer, allMapData.gid).then(() => {
      maps.updateRasterLayer(currentMap.mapRef.current, 'selected', layer.serverCall);
      setAllMapData((prevData) => ({
        ...prevData,
        baselineInfo: panelConfig[layer.product].baselineStats,
        selectedLayer: layer,
      }));
    });
    setLayerDropdownIsOpen(false);
  };

  const setSelection = (gid, adminLevel) => {
    gidRef.current = gid;
    setAllMapData((prevData) => ({
      ...prevData,
      gid,
      adminLevel,
    }));
  };

  const setDisplayText = (displayText) => {
    setAllMapData((prevData) => ({
      ...prevData,
      displayText,
    }));
  };

  const fetchPanelData = async (selectedLayer, gid) => {
    const product = selectedLayer.product;
    const baselineData = await endpoints.getBaseline(gid, product);
    const chartData = await endpoints.getStats(gid, product);
    const aggSummaryData = await endpoints.getTooltip(gid, product);

    setAllMapData((prevData) => ({
      ...prevData,
      chartData,
      baselineData: baselineData ? baselineData[product] : baselineData,
      aggSummaryData,
      baselineInfo: panelConfig[selectedLayer.product].baselineStats,
    }));
  };

  useEffect(() => {
    gidRef.current = gid;
  }, [allMapData.gid]);

  useEffect(() => {
    fetchPanelData(allMapData.selectedLayer, allMapData.gid);
  }, [allMapData.selectedLayer, allMapData.gid]);

  useEffect(() => {}, [allMapData]);

  const currentSelection = {
    gidRef,
    gid: allMapData.gid,
    adminLevel: allMapData.adminLevel,
    displayText: allMapData.displayText,
    chartData: allMapData.chartData,
    baselineData: allMapData.baselineData,
    baselineInfo: allMapData.baselineInfo,
    aggSummaryData: allMapData.aggSummaryData,
    selectedLayer: allMapData.selectedLayer,
    changeLayer,
    layerDropdownIsOpen,
    setSelection,
    setDisplayText,
    setLayerDropdownIsOpen,
    setAllMapData,
    allMapData
  };

  return (
    <SelectionContext.Provider value={{ currentSelection }}>
      {children}
    </SelectionContext.Provider>
  );
};

export const useCurrentSelection = () => {
  const context = useContext(SelectionContext);

  if (context === undefined) {
    throw new Error(
      "useCurrentSelection must be used within a SelectionProvider"
    );
  }

  return context;
};
