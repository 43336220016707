import { useEffect } from "react";
import * as styles from "./styles";
import * as components from "../components";
import * as dropdown from "../components/Dropdown";
import { maps, layers } from "../components/Mapbox/helpers";
import {
  QuestionMarkIcon,
  LayersIcon,
} from "../components/Icons";
import * as constants from "../constants";
import mapboxgl from "mapbox-gl";

import * as context from "../contexts";

// Initialize Mapbox
mapboxgl.accessToken =
  "pk.eyJ1IjoiYWFzaGFyeSIsImEiOiJjbG5leHZmODAwZ2FhMnJyaWxtZzZhem5tIn0.ybRQgfdnPYGeXrgbU65Xiw";

export default function MapBase() {
  const { currentSelection } = context.useCurrentSelection();
  const { currentMap } = context.useCurrentMap();
  const { currentModal } = context.useCurrentModal();

  //-----------------------------------------//
  //--- INITIALIZE MAP LOAD FUNCTIONALITY ---//
  //-----------------------------------------//
  useEffect(() => {
    const mapInstance = new mapboxgl.Map({
      container: "map", // ID of map container
      style: "/alidade_satellite.json",
      center: [-51.92528, -14.235004], // BRA (longitude, latitude)
      maxZoom: constants.maxZoom,
      minZoom: constants.minZoom,
      attributionControl: false, 
      pitch: 0,           // Disable 3D view (set pitch to 0)
      bearing: 0,         // Set bearing to 0 (north-facing)
      pitchWithRotate: false, // Disable pitch change with right-click drag
      dragRotate: false,      // Disable map rotation with right-click drag
      touchPitch: false       // Disable pitch change with touch gestures
    });

    // Add the compact attribution control
    mapInstance.addControl(
      new mapboxgl.AttributionControl({
        compact: true, // Force compact mode on all screen sizes
      })
    );

    currentMap.mapRef.current = mapInstance;
    let currentMapRef = currentMap.mapRef.current;

    mapInstance.on("load", () => {
      currentMap.setMapLoaded(true);
      maps.onMapLoad(
        mapInstance,
        currentSelection.gidRef,
        currentSelection.setSelection
      );

      layers.loadEvents(currentMapRef, currentModal.openModal);
      layers.addNoDataLayer(mapInstance);
    });

    return () => {
      if (currentMapRef) {
        if (currentMapRef.off) {
          currentMapRef.off("load");
        }
        if (
          currentMap.scaleControlRef.current &&
          currentMap.mapRef.removeControl
        ) {
          currentMapRef.removeControl(currentMap.scaleControlRef.current);
        }
        currentMapRef.remove();
        currentMapRef = null; // Clear the reference to avoid any unintended access
      }
    };
  }, []);

  //------------------------//
  //--- MODAL PROPERTIES ---//
  //------------------------//
  const modalProps = {
    title: currentModal.modalTitle,
    desc: currentModal.modalDesc,
    isOpen: currentModal.modalIsOpen,
    onRequestClose: currentModal.closeModal,
  };

  //-------------------------//
  //--- BUTTON PROPERTIES ---//
  //-------------------------//
  const buttonProps = {
    onClick: () => {
      currentModal.openModal(
        constants.questionMarkModal.title,
        "",
        constants.questionMarkModal.contents
      );
    },
    backgroundColor: "whiteButton",
    textColor: "black",
    roundedSize: "rounded-md",
  };

  //------------------------//
  //--- LAYER PROPERTIES ---//
  //------------------------//
  const layerDropdownProps = {
    onClick: null, // the dropdown component will handle the action of the button
    backgroundColor: "whiteButton",
    textColor: "black",
    roundedSize: "rounded-md",
    className: "dropdownTitleElement",
  };

  const dropdownProps = {
    icon: (
      <components.Button {...layerDropdownProps} width={10}>
        <LayersIcon className="dropdownTitleElement" />
      </components.Button>
    ),
    isOpen: currentSelection.layerDropdownIsOpen,
    setIsOpen: () =>
      currentSelection.setLayerDropdownIsOpen(
        !currentSelection.layerDropdownIsOpen
      ),
  };

  const dropdownContentsProps = {
    options: constants.layerOptions,
    isOpen: currentSelection.layerDropdownIsOpen,
    selected: currentSelection.selectedLayer,
    handleItemClick: currentSelection.changeLayer,
  };

  //-----------------------//
  //--- RENDER MAP BASE ---//
  //-----------------------//
  return (
    <>
      <div className={styles.screen}>
        <components.Onboarding />

        <div className={styles.searchAndLayer}>
          <context.SearchProvider>
            <components.Search />
          </context.SearchProvider>

          <components.MapTitle title={constants.mapTitle} />

          {/* Mobile-only button next to Search */}
          <div className={styles.layerButtonMobile}>
            <dropdown.Dropdown {...dropdownProps} />
          </div>
          {/* Mobile-only Layer button will display dropdown contents under search/button */}
          <div className={styles.dropdownContentsContainer}>
            <dropdown.Body {...dropdownContentsProps} />
          </div>
        </div>

        <div className={styles.buttonBottomRight}>
          <components.Button {...buttonProps}>
            <QuestionMarkIcon />
          </components.Button>
        </div>

        <components.Mapbox />

        <components.MetricsPanel>
          {currentSelection.selectedLayer.product && (
            <components.PanelContents />
          )}
        </components.MetricsPanel>
      </div>

      <components.Modal {...modalProps}>
        {currentModal.modalContent}
      </components.Modal>
    </>
  );
}
