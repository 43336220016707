import { LineChart } from "../";
import { ChartFilter } from "./components";
import { lineStyles } from "./constants";
import { useCurrentChart } from "../../../../../contexts/ChartProvider";

export default function Chart() {
  const { currentChart } = useCurrentChart();
  const category = currentChart.category;
  const data = currentChart.chartData;
  const initialChartRange = currentChart.chartRange;
  const xAxisLimits = currentChart.xAxisLimits;
  const onFilterChange = currentChart.onFilterChange;
  const unit = currentChart.units;

  const chartFilterProps = {
    data: data[category],
    xAxisLimits,
    onFilterChange,
  };

  const lineChartProps = {
    id: category,
    unit,
    chartRange: initialChartRange[category],
    lineStyles,
  };

  // Function to filter multiLineData to remove empty data (eg for STABLE_FOREST)
  const filterMultiLineData = (data) => {
    return data.filter((item) => item.values.length > 0);
  };

  return (
    <>
      <div className="relative">
        {data && (
          <ChartFilter {...chartFilterProps}>
            {(data, xAxisLimits) => (
              <LineChart
                {...lineChartProps}
                multiLineData={filterMultiLineData(data)}
                xAxisLimits={xAxisLimits}
              />
            )}
          </ChartFilter>
        )}
      </div>
      {/* Temporary while we find permanent solution after 10.22.24 launch */}
      <div className="text-[10px] italic mb-2">
        *REDD+AI may underestimate tree cover loss for years 2017-2020 due to the
        six-month cadence of NICFI data during this period
      </div>
    </>
  );
}
