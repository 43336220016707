import clsx from "clsx";

export const popUpTitleContainer = clsx(
  "flex items-start justify-between border-b border-slate-300 bg-white pb-2 mb-2"
);
export const popUpTitleText = clsx("lg:text-xl font-semibold text-black pb-2");
export const popUpSubTitleText = clsx("flex items-center gap-2 pb-2");
export const popUpCloseContainer = clsx("w-1/4 flex justify-end items-end");
export const popUpContentContainer = clsx(
  "bg-white flex h-5/6 w-full flex-col overflow-y-auto py-2"
);

export const closeButtonText = clsx("text-grey-500 font-semibold");
export const popUpCloseTextContainer = clsx(
  "flex justify-end items-end w-full"
);
export const popUpContainer = clsx("popUpContainer");