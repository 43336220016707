import { ReferenceLineIcon } from "../../../../../Icons";
import * as styles from "./styles";
import * as constants from '../constants'

export default function Desktop() {
  return (
    <>
      {/* SEARCH BAR INFO */}
      <div className={styles.search}>
        <ReferenceLineIcon width={500} />
        <div className="mt-2">
          {constants.search}
        </div>
      </div>

      {/* ZOOM INFO */}
      <div className={styles.zoom} style={{ top: "calc(40% + 38px)" }}>
        <div className="rotate-90">
          <ReferenceLineIcon width={100} />
        </div>
        <div className="-mr-[40px]">{constants.zoom}</div>
      </div>

      {/* QUESTION MARK INFO */}
      <div className={styles.question}>
        <div className="rotate-90">
          <ReferenceLineIcon width={45} />
        </div>
        <div className="-mr-[10px]">{constants.method}</div>
      </div>

      {/* ------------------------ */}
      {/* ------ LEFT SIDE ------ */}
      {/* ------------------------ */}

      {/* LAYER DROPDOWN INFO */}
      <div className={styles.layerDropdown}>
        <div className="rotate-90">
          <ReferenceLineIcon width={60} />
        </div>
        <div className="-ml-2">Change data layers</div>
      </div>

      {/* TIMELINE INFO */}
      <div className={styles.timeline}>
        <div className="rotate-90">
          <ReferenceLineIcon width={60} />
        </div>
        <div className="-ml-2">Adjust time frames</div>
      </div>

      {/* DOWNLOAD + LEARN INFO */}
      <div className={styles.download}>
        <div className="rotate-90">
          <ReferenceLineIcon width={100} />
        </div>
        <div>
          <div className="-ml-4">{constants.dwnld}</div>
          <div className="-ml-4">{constants.report}</div>
        </div>
      </div>
    </>
  );
}
