import clsx from "clsx";
import { formatNumber } from "../../helpers";

export default function BaselineStat({ title, value, unit }) {
  return (
    <div key={title} className={baselineStatContainerStyle}>
      <div className="flex justify-between">
        <div className="items-center gap-1 flex">
          <div className={baselineStatTitleStyle}>{title}</div>
        </div>

        <div className={baselineValueAndUnitStyle}>
          <div className="text-black text-sm font-bold">
            {formatNumber(value, unit)}
          </div>
          <div className="text-neutral-500 font-light text-xs">
            {unit}
            {title.toLowerCase().includes("rate") ? "/yr" : ""}
          </div>
        </div>
      </div>
    </div>
  );
}

const baselineStatContainerStyle = clsx(
  "w-full pt-2 flex-col inline-flex max-w-[410px]"
);
const baselineStatTitleStyle = clsx(
  "text-black text-sm tracking-wide leading-tight"
);
const baselineValueAndUnitStyle = clsx(
  "gap-1 flex justify-end items-center font-mono"
);
