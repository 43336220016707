import clsx from "clsx";

export const input = clsx(
  "w-full p-2 text-black text-l font-semibold leading-normal"
);
export const searchDropdownBody = clsx(
  "border mt-2 rounded-lg bg-white max-h-96 overflow-y-auto"
);
export const dropdownBodyContainer = clsx(
  "absolute top-full left-0 w-full bg-white border p-2 z-50 rounded-b-lg"
);
export const searchParentContainer = clsx(
  "h-10 lg:w-[500px] lg:absolute mt-6 px-4 py-2 bg-white rounded-lg flex justify-between items-center z-50"
);
export const noData = clsx("mt-2 rounded-lg bg-white max-h-20 text-center p-2");
