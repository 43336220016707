import isNumberInRange from "./isNumberInRange";

export const ha = "ha";
export const m2 = "m2";
export const Mha = "Mha";

export const initialAreaUnit = ha;
const areaUnitOrder = { [Mha]: 1, [ha]: 2, [m2]: 3 };

const formatAreaValue = (value) => {
  /**
   * Format area value (Ha) based on magnitude:
   * - If value is greater than 100,000, display in million hectares (Mha).
   * - If value is less than 0.01, display in square meters (m2).
   * - Otherwise, display in hectares (Ha).
   */

  if (value > 100000) {
    return Mha;
  } else if (value < 0.01) {
    return m2;
  } else {
    return ha;
  }
};

export default function getUnitsForSingleChart(data, range) {
  let tempUnits = initialAreaUnit;

  data.forEach((v) => {
    v.values.forEach((val) => {
      const areaValue = formatAreaValue(val.ha, val);

      // will determine the area unit based on the range of the chart
      if (
        isNumberInRange(val.year, range) &&
        areaUnitOrder[areaValue] < areaUnitOrder[initialAreaUnit]
      ) {
        tempUnits = areaValue;
      }
    });
  });

  return tempUnits;
}
