import { margin, height } from "../../constants";
import { addColorsToChartRange } from "../../../Charts/helpers";
import { getMinValueFromArray, getMaxValueFromArray } from "../../../../helpers";

const getInt = (str) => parseInt(str);

export default function addYearBasedBackground(
  svg,
  xScale,
  yearRange,
  chartRange,
  chartWidth
) {
  const [startYear, endYear] = yearRange;
  const minRange = getMinValueFromArray(chartRange);
  const maxRange = getMaxValueFromArray(chartRange);

  // Retry logic to ensure colors are loaded
  const getColorData = () => {
    const allColors = addColorsToChartRange([minRange, maxRange]);
    return allColors.filter((year) => year.label !== undefined);
  };

  const getLabel = (label, maxStartYr, maxEndYr) => {
    return label.includes("-") && maxEndYr - maxStartYr > 1 ? `${maxStartYr}-${maxEndYr - 1}` : maxStartYr;
  };

  // Function to add background segments and labels
  const addSegments = (colors) => {
    const segments = colors
      .map((year) => {
        const yrSplit = year.label.split("-");
        const startYr = getInt(yrSplit[0]);
        const endYr = yrSplit.length > 1 ? getInt(yrSplit[1]) + 1 : startYr + 1;

        // Filter out segments outside the yearRange
        if (startYr > endYear || endYr <= startYear) {
          return null;
        }

        const maxStartYr = Math.max(startYr, startYear);
        const maxEndYr = Math.min(endYr, endYear + 1);
        const startX = xScale(maxStartYr);
        const endX = xScale(maxEndYr);
        const segmentWidth = endX - startX;

        // Determine the color for the segment
        const colorInfo = year.hex;
        return {
          startX,
          segmentWidth,
          color: colorInfo,
          label: getLabel(year.label, maxStartYr, maxEndYr),
        };
      })
      .filter((segment) => segment !== null);

    // Append background rectangles for each segment
    segments.forEach(({ startX, segmentWidth, color, label }) => {
      svg
        .append("rect")
        .attr("x", startX + margin.left)
        .attr("y", 0)
        .attr("width", segmentWidth)
        .attr("height", height)
        .attr("fill", color)
        .attr("fill-opacity", 0.5);

      svg
        .append("rect")
        .attr("x", startX + margin.left)
        .attr("y", height)
        .attr("width", segmentWidth)
        .attr("height", 8)
        .attr("fill", color);

      // Add labels to the x-axis
      svg
        .append("text")
        .attr("x", startX + margin.left + segmentWidth / 2)
        .attr("y", height + 20) // Position below the x-axis
        .attr("text-anchor", "middle")
        .attr("fill", "#000")
        .attr("font-size", "12px")
        .attr("font-family", "'Roboto Mono', monospace")
        .text(label);
    });
  };

  // Initial call to add segments
  let colorData = getColorData();
  if (colorData.length === 0) {
    const retryInterval = setInterval(() => {
      colorData = getColorData();
      if (colorData.length > 0) {
        clearInterval(retryInterval);
        addSegments(colorData);
      }
    }, 100); // Retry every 100ms until colors are loaded
  } else {
    addSegments(colorData);
  }
}
