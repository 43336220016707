import mapboxgl from "mapbox-gl";
import { getAdmin } from "../endpoints";
import { moveToLoadedAdmin, moveViewToClickedLayer } from "../view";
import * as layers from "../layers";
import { addGeoJsonSource } from "../geojson";
import * as constants from "../constants";
import { layerOptions, minZoom, maxZoom } from "../../../../constants";
import { clickable } from "../layers/constants";

const cancelEvent = (e) => {
  return e.originalEvent.cancelMapInteractivityOnEventClick;
};

const isClickableLayer = (e, map) => {
  // Get all features/layers at the click point if they are 'constants.clickable' layer (special event)
  const clickableFeatures = map.queryRenderedFeatures(e.point, {
    layers: [clickable],
  });

  // If the click was on 'constants.clickable', do nothing and return true
  if (clickableFeatures.length) {
    return true;
  }

  return false;
};

export const updateRasterLayer = (map, layer, url) => {
  const sourceId = `${layer}-source`;
  const layerId = `${layer}-layer`;

  // when new layer is selected, remove the toggle layer (stable forest)
  if (layer === "selected" && map.getLayer("toggle-layer")) {
    map.removeLayer("toggle-layer");
    map.removeSource("toggle-source");
  }

  if (map.getSource(sourceId)) {
    // If source exists, update its tiles property
    map.getSource(sourceId).setTiles([url]);
  } else {
    // If source does not exist, add it and the corresponding layer
    map.addSource(sourceId, {
      type: "raster",
      tiles: [url],
      tileSize: 256,
    });

    map.addLayer(
      {
        id: layerId,
        type: "raster",
        source: sourceId,
      },
      "country-boundaries-outline-layer"
    ); // Add the layer before "country-boundaries-outline-layer"
  }
};

const addAdmin0Layer = (map, setSelection) => {
  const admin0BaseLayer = "gadm_v410_admin0_global";
  return new Promise((resolve, reject) => {
    try {
      const countryBoundariesSrc = {
        type: "vector",
        url: `mapbox://aashary.${admin0BaseLayer}`,
      };
      const countryBoundariesLayer = {
        id: "country-boundaries-layer",
        type: "fill",
        source: countryBoundariesSrc,
        "source-layer": admin0BaseLayer,
        paint: {
          "fill-color": "transparent",
          "fill-opacity": 1,
        },
        minZoom,
        maxZoom,
      };
      const countryBoundariesOutlineLayer = {
        id: "country-boundaries-outline-layer",
        type: "line",
        source: countryBoundariesSrc,
        "source-layer": admin0BaseLayer,
        paint: {
          "line-color": "white",
          "line-width": 1,
          "line-opacity": 0.3,
        },
        interactive: false, // Make the layer non-clickable
      };

      map.addSource("country-boundaries-src", countryBoundariesSrc);
      map.addLayer(countryBoundariesLayer);
      map.addLayer(countryBoundariesOutlineLayer);

      map.on("click", "country-boundaries-layer", async function (e) {
        if (isClickableLayer(e, map)) return;

        try {
          const gid = e.features[0].properties.GID_0;

          setSelection(gid, 0);
        } catch (error) {
          console.log("something went wrong", error);
        }
      });

      resolve(); // Resolve the promise once the layers are added
    } catch (error) {
      reject(error); // Reject the promise if there's an error
    }
  });
};

export const onMapLoad = (map, selectedGID, setSelection) => {
  // Add zoom in/zoom out control
  var nav = new mapboxgl.NavigationControl();
  map.addControl(nav, "bottom-right");

  // Add the admin0 layer
  addAdmin0Layer(map, setSelection)
    .then(() => {
      // Once the layer is added, update the raster layer
      updateRasterLayer(map, "selected", layerOptions[0].serverCall);
    })
    .catch((error) => {
      console.error(
        "Error adding admin0 layer or updating raster layer:",
        error
      );
    });

  // Handle map errors
  map.on("error", function (e) {
    console.log("Map error:", e.error.message);
  });
};

const cleanUpLayers = async (map, gidAdminArray, adminLevel) => {
  const source = layers.getSourceName(adminLevel);
  await layers.removeSourceLayer(map, constants.selectedOutline, source);
  await layers.removeSourceLayer(map, constants.selectedOutlineFill, source);

  for (let index = gidAdminArray.length - 1; index >= 0; index--) {
    await layers.removeSourceLayer(
      map,
      layers.getLayerName(index),
      layers.getSourceName(index)
    );
    await layers.removeSourceLayer(
      map,
      layers.getLayerChildrenName(index),
      layers.getSourceChildrenName(index)
    );
  }
};

export const loadAdminData = async (map, state) => {
  const selectedAdmin = state.selectedAdmin;
  const gid = state.gidAdminArray[selectedAdmin];
  const source = `admin-${selectedAdmin}-src`;
  const layer = `admin-${selectedAdmin}-layer`;

  // Get data and move to coordinates
  const data = await getAdmin(gid);
  if (!data) return;

  // let the user know the click triggered by updating the display text first
  state.setDisplayText(data?.name);

  await cleanUpLayers(map, state.gidAdminArray, selectedAdmin);

  /* LOADING LAYERS */
  // when selectedAdmin is 2, outline grandparents children (admin 0)
  if (data?.aunties_url) {
    const grandparentAdmin = data.admin_level - 2; // aunties_url will always be two levels above selected admin
    layers.addChildOutlineLayer(
      map,
      layers.getSourceChildrenName(grandparentAdmin),
      layers.getLayerChildrenName(grandparentAdmin),
      data.aunties_url
    );
  }

  // when selectedAdmin is 0 or 1 is selected, outline parents children (admin - 1)
  if (data?.siblings_url) {
    const parentAdmin = data.admin_level - 1; // siblings_url will always be one level above selected admin
    layers.addChildOutlineLayer(
      map,
      layers.getSourceChildrenName(parentAdmin),
      layers.getLayerChildrenName(parentAdmin),
      data.siblings_url
    );
  }

  // load and outline the selected gid
  if (data?.url) {
    await addGeoJsonSource(map, data?.url, source);
    await layers.addTransparentLayer(map, source, layer);
    await layers.addOutlineLayer(
      map,
      source,
      constants.selectedOutline,
      "#dff840"
    );

    /* MOVE VIEW */
    moveToLoadedAdmin(map, data, gid, selectedAdmin);
  }

  // add children outline layer for the selected admin
  if (data?.children_url) {
    const url = data.children_url;
    layers.addChildOutlineLayer(
      map,
      layers.getSourceChildrenName(selectedAdmin),
      layers.getLayerChildrenName(selectedAdmin),
      url
    );

    const clickableParams = {
      adminLevel: selectedAdmin + 1,
      gidRef: gid,
      setSelection: state.setSelection,
      map,
    };
    map.on("click", layers.getLayerChildrenName(selectedAdmin), (e) =>
      handleChildrenClick(e, clickableParams)
    );
  }

  state.updateLoadedState(selectedAdmin, gid);
  layers.setLayerOrder(map);
};

export const handleChildrenClick = (e, params) => {
  if (cancelEvent(e)) return;

  const adminLevel = params.adminLevel;
  const gidProp = `GID_${adminLevel}`;
  const gid = e.features[0].properties[gidProp];
  const sameGid = params.gidRef === gid;

  if (!sameGid && params.gidRef && gid) {
    const clickedCoords = [e.lngLat.lng, e.lngLat.lat];

    params.setSelection(gid, adminLevel);
    moveViewToClickedLayer(
      params.map,
      params.gidRef,
      e.features[0].geometry.coordinates[0],
      clickedCoords
    );
  }
};
