import * as constants from "./constants";

const moveLayer = (map, layer) => {
  if (map.getLayer(layer)) {
    map.moveLayer(layer, null);
  }
};

export default function setLayerOrder(map) {
  // Get the style layers from the map
  const style = map.getStyle();
  const allLayers = style.layers;

  // Separate symbol layers and other layers (text and icons)
  const symbolLayers = allLayers
    .filter((layer) => layer.type === "symbol")
    .map((layer) => layer.id);

  const nonSymbolLayers = [
    "country-boundaries-layer",
    "admin-0-layer",
    "admin-1-layer",
    "admin-2-layer",
    constants.outer,
    constants.inner,
  ];

  // Combine non-symbol layers and symbol layers, leaving clickable at the end
  const layers = [...nonSymbolLayers, ...symbolLayers];

  // Move each layer in the new order
  layers.forEach((layer) => {
    moveLayer(map, layer);
  });

  // Move 'constants.clickable' to the absolute top of the layer stack
  moveLayer(map, constants.clickable); // Ensure this is the top-most layer
}

