import clsx from "clsx";
import { useCurrentSelection } from "../../../../../contexts";

export default function HeaderText() {
  const { currentSelection } = useCurrentSelection();

  return (
    <div className={parentContainerStyle}>
      <div>{currentSelection.selectedLayer.displayName} in</div>
      <div className="font-bold ml-1">
         {currentSelection.displayText}
      </div>
    </div>
  );
}

const parentContainerStyle = clsx("flex items-center justify-center text-sm w-full m-auto my-4");
