import { select } from "d3-selection";

const d3 = {
  select,
};

export default function initiateChart(containerRef, width, height, margin) {
  return d3
    .select(containerRef.current)
    .append("svg")
    .attr("width", width + margin.right + margin.left)
    .attr("height", height + margin.top + margin.bottom)
    .attr(
      "viewBox",
      `0 0 ${width} ${height + margin.top + margin.bottom}`
    )
    .attr("preserveAspectRatio", "xMinYMin meet");
}
