import { useState } from "react";
import clsx from "clsx";
import * as context from "../../contexts";

const portalId = "22828643";
const formId = "7eb85ff1-4d17-45f7-8a30-50914e8ee658";

export default function ReportAnIssue() {
  const { currentSelection } = context.useCurrentSelection();
  const { currentMap } = context.useCurrentMap();
  const bounds = currentMap.mapRef.current.getBounds();
  const swBounds = bounds.getSouthWest();
  const neBounds = bounds.getNorthEast();

  const coordinates = "coordinates";
  const describe_the_issue = "describe_the_issue";
  const email = "email";

  const [formData, setFormData] = useState({
    email: "",
    coordinates: "",
    describe_the_issue: "",
    selected_layer: currentSelection.selectedLayer.product,
    gid: currentSelection.gid,
    sw_bounds: `lat: ${swBounds.lat}, lng: ${swBounds.lng}`,
    ne_bounds: `lat: ${neBounds.lat}, lng: ${neBounds.lng}`,
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const hubspotUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

    const data = {
      fields: Object.keys(formData).map((key) => ({
        name: key,
        value: formData[key],
      })),
      context: {
        pageUri: window.location.href,
        pageName: document.title,
      },
    };

    try {
      const response = await fetch(hubspotUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      setSuccessMessage(
        "Thank you to contributing to our mission of transparent and accurate activity data on tropical forests."
      );
      setErrorMessage("");
      setFormData({
        email: "",
        describe_the_issue: "",
        coordinates: "",
        selected_layer: currentSelection.selectedLayer.product,
        gid: currentSelection.gid,
        sw_bounds: `lat: ${swBounds.lat}, lng: ${swBounds.lng}`,
        ne_bounds: `lat: ${neBounds.lat}, lng: ${neBounds.lng}`,
      });
    } catch (error) {
      console.error("Error submitting the form:", error);
      setErrorMessage("Failed to submit the form, please try again.");
      setSuccessMessage("");
    }
  };

  return (
    <div className="w-full my-4">
      <p class="mb-6">
        At CTrees, we work with the global scientific community and users like
        you to enhance our tools. Your feedback strengthens our understanding of
        tropical deforestation and degradation.
      </p>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className={labelStyle} htmlFor={email}>
            Email*
          </label>
          <input
            type={email}
            id={email}
            name={email}
            value={formData.email}
            onChange={handleChange}
            className={inputStyle}
            required
          />
        </div>
        <div className="mb-4">
          <div>
            <label className={labelStyle} htmlFor={coordinates}>
              Coordinates (right-click on the map to copy)
            </label>
            <input
              type={coordinates}
              id={coordinates}
              name={coordinates}
              value={formData.coordinates}
              onChange={handleChange}
              className={inputStyle}
              placeholder="Latitude, Longitude"
            />
          </div>
        </div>
        <div className="mb-4">
          <label className={labelStyle} htmlFor={describe_the_issue}>
            Describe the issue*
          </label>
          <textarea
            id={describe_the_issue}
            name={describe_the_issue}
            value={formData.describe_the_issue}
            onChange={handleChange}
            className={`${inputStyle} h-24 resize-none`}
            placeholder="Please provide as many specifics as possible, including location of the region you are analyzing and a brief description of the data issue. While we may not be able to respond to each report individually, rest assured that all submissions will be carefully reviewed by our team. "
            required
          />
        </div>

        {successMessage && (
          <p className="text-[#167307] font-semibold mb-4">{successMessage}</p>
        )}
        {errorMessage && (
          <p className="text-red-500 font-semibold mb-4">{errorMessage}</p>
        )}
        <div className="flex items-center justify-between">
          <button type="submit" className={submitStyle}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

const inputStyle = clsx(
  "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
);
const labelStyle = clsx("block text-gray-700 text-sm font-bold mb-2");
const submitStyle = clsx(
  "bg-yellowButton hover:bg-yellowButtonHover text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
);
