// Fetch GeoJSON data from the S3 bucket
export async function fetchGeoJsonData(url) {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error("Failed to fetch GeoJSON data");
  }
  const data = await response.json();
  return data;
}

// creates source for geojson (layer will need the source)
export async function addGeoJsonSource(map, geojson, source) {
  if (!map.getSource(source)) {
    try {
      await map.addSource(source, {
        type: "geojson",
        data: geojson,
      });
      return;
    } catch (e) {
      console.log(`Geojson unable to load ${source}, try again`, e);
    }
  }
}
