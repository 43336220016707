import { Toggle } from "../../../";
import { maps, layers } from "../../../Mapbox/helpers";
import {
  stableForestRaster,
  forestCoverTitle,
  stableForestColor,
} from "../../../../constants";
import * as context from "../../../../contexts";

export default function Footer() {
  const { currentMap } = context.useCurrentMap();
  const { currentSelection } = context.useCurrentSelection();
  const stableForestToggle = currentSelection.selectedLayer.stableForestToggle;

  const handleStableForestToggle = (isChecked) => {
    maps.updateRasterLayer(
      currentMap.mapRef.current,
      'toggle',
      isChecked ? stableForestRaster : currentSelection.selectedLayer.serverCall
    );
  };

  const handleEventsToggle = (isChecked) => {
    layers.togglePointsVisibility(currentMap.mapRef.current, isChecked);
  };

  return (
    <div className="space-y-3">
      {stableForestToggle && (
        <Toggle
          label={forestCoverTitle}
          setIsEnabled={handleStableForestToggle}
          circle={stableForestColor}
        />
      )}
      <Toggle label="Special Events" setIsEnabled={handleEventsToggle} checked={true} />
    </div>
  );
}
