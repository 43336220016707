function ArrowUpperRight({fill = "black"}) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 0L16 16L14.1502 16L14.1502 3.15776L1.30796 16L0 14.692L12.8422 1.8498L0 1.8498L0 0L16 0Z"
        fill={fill}
      />
    </svg>
  );
}

export default ArrowUpperRight;
