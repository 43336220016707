import { useEffect, useState, useRef } from "react";
import { select } from "d3-selection";
import { extent } from "d3-array";
import { axisBottom } from "d3-axis";
import { scaleLinear } from "d3-scale";
import clsx from "clsx";

import { margin } from "../constants";
import { Tooltip } from "../components";
import * as chartHelpers from "../helpers";
import * as lineChartHelpers from "./helpers";

import { useCurrentChart } from "../../../../../../contexts/ChartProvider";

const d3 = {
  select,
  extent,
  axisBottom,
  scaleLinear,
};

export default function LineChart({
  id,
  multiLineData,
  xAxisLimits,
  lineStyles,
}) {
  const { currentChart } = useCurrentChart();
  const chartContainerRef = currentChart.chartContainerRef;
  const chartWidth = currentChart.chartWidth;
  const chartHeight = currentChart.chartHeight;
  const category = currentChart.category;
  const unit = currentChart.units;

  const [tooltip, setTooltip] = useState(
    chartHelpers.getTooltipInitiation(unit)
  );

  useEffect(() => {
    if (d3.select(chartContainerRef.current)) {
      // Remove all child elements within the SVG or chart container
      d3.select(chartContainerRef.current).selectAll("*").remove();
    }

    /* INITIALIZE CHART */
    const svg = chartHelpers.initiateChart(
      chartContainerRef,
      chartWidth,
      chartHeight,
      margin
    );

    /* X-AXIS and SCALES */
    const numXAxisValues = xAxisLimits[1] - xAxisLimits[0] + 1; // Number of years being charted
    const xScale = lineChartHelpers.getXScale(xAxisLimits, chartWidth);

    /* Y-AXIS and SCALES */
    const yExtent = d3.extent(
      [].concat(
        ...multiLineData.map((series) => series.values.map((d) => d[unit]))
      )
    );
    const minYValue = yExtent[0];
    const maxYValue = yExtent[1];

    const yScale = lineChartHelpers.getYScale(minYValue, maxYValue);

    lineChartHelpers.addYearBasedBackground(
      svg,
      xScale,
      xAxisLimits,
      currentChart.chartRange,
      chartWidth
    );

    // Modify the y-axis to show labels only on the grid lines
    const yAxis = lineChartHelpers.getTicksOnYAxis(yScale, unit);
    lineChartHelpers.addLabelsToYAxis(svg, yAxis);
    chartHelpers.addUnitLabelOnYAxis(svg, unit);
    lineChartHelpers.addGridlinesToYAxis(svg, yScale, chartWidth, unit);

    /* GENERATE LINES */
    lineChartHelpers.generateLines(
      svg,
      multiLineData,
      xScale,
      yScale,
      numXAxisValues,
      unit,
      lineStyles,
      chartWidth
    );

    /* CHART ACCESSORIES */
    lineChartHelpers.addTooltipFunctionality(
      svg,
      multiLineData,
      xScale,
      yScale,
      setTooltip,
      unit,
      numXAxisValues,
      chartWidth,
      chartHeight
    );
  }, [multiLineData, xAxisLimits]);

  return (
    <>
      <Tooltip {...tooltip} lineStyles={lineStyles} />
      <div ref={chartContainerRef} id={id} className={chartRefStyle}></div>
    </>
  );
}

const chartRefStyle = clsx("panelChart flex items-center justify-center -ml-2");
