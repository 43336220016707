export const admin0 = 0;
export const admin1 = 1;
export const admin2 = 2;
export const admin0src = 'admin-0-src';
export const admin1src = 'admin-1-src';
export const admin2src = 'admin-2-src';
export const admin0layer = 'admin-0-layer';
export const admin1layer = 'admin-1-layer';
export const admin2layer = 'admin-2-layer';
export const selectedOutline = "selected-outline";
export const selectedOutlineFill = "selected-outline-fill";
