export default function addUnitLabelOnYAxis(svg, unit) {
    const yPosition = -14; // Position the text element above the y-axis

    // Set the overflow property of the SVG container to visible
    svg.style("overflow", "visible");

    // Create the text element
    const textElement = svg
        .append("text")
        .attr("class", "unit-label")
        .attr("x", 50)
        .attr("y", yPosition)
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .style("fill", "#6B6B6B")
        .attr("font-family", "'Roboto Mono', monospace")
        .style("font-size", "10px")
        .text(unit);

    // Move the text element to the end of the SVG to ensure it's on top
    textElement.raise();

    return textElement;
}