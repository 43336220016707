import { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { admin, maps, constants } from "./helpers";
import Scale from "./components/Scale";
import { useCurrentSelection, useCurrentMap } from "../../contexts";
import Coordinates from "./components/Coordinates";

// this comment and mapboxgl.workerClass NEED to be right under each other
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const Mapbox = () => {
  const { currentSelection } = useCurrentSelection();
  const { currentMap } = useCurrentMap();

  // [constants.admin0 (country), constants.admin1 (state), constants.admin2 (county)]
  const [selectedGIDs, setSelectedGIDs] = useState(["BRA", null, null]);
  const [loadedGIDs, setLoadedGIDs] = useState([null, null, null]);
  const [lngLat, setLngLat] = useState({ lng: 0, lat: 0 });

  const adminLevelRef = useRef(currentSelection.adminLevel);

  const getUpdatedStateArray = (updatedArray, selectedAdmin, selectedGID) => {
    const newAdmin0 = admin.getAdmin0Code(selectedGID);

    if (selectedAdmin === constants.admin0) {
      updatedArray[constants.admin0] = selectedGID;
      updatedArray[constants.admin1] = null;
      updatedArray[constants.admin2] = null;
    } else if (selectedAdmin === constants.admin1) {
      if (updatedArray[constants.admin0] !== newAdmin0) {
        updatedArray[constants.admin0] = newAdmin0;
      }
      updatedArray[constants.admin1] = selectedGID;
      updatedArray[constants.admin2] = null;
    } else if (selectedAdmin === constants.admin2) {
      if (updatedArray[constants.admin0] !== newAdmin0) {
        updatedArray[constants.admin0] = newAdmin0;
      }
      if (updatedArray[constants.admin1] !== admin.getAdmin1Code(selectedGID)) {
        updatedArray[constants.admin1] = admin.getAdmin1Code(selectedGID);
      }
      updatedArray[constants.admin2] = selectedGID;
    }
    return updatedArray;
  };

  // update once map layers are loaded
  const updateLoadedState = (selectedAdmin, selectedGID) => {
    const updateArray = [...loadedGIDs];

    setLoadedGIDs(
      getUpdatedStateArray(updateArray, selectedAdmin, selectedGID)
    );
  };

  // update map with selected gid
  const updateSelectedGidState = (selectedAdmin, selectedGID) => {
    const updateArray = [...selectedGIDs];

    setSelectedGIDs(
      getUpdatedStateArray(updateArray, selectedAdmin, selectedGID)
    );

    syncMapWithState(updateArray, selectedAdmin);
  };

  const syncMapWithState = async (updateArray, selectedAdmin) => {
    const map = currentMap.mapRef.current;
    const state = {
      gidAdminArray: updateArray,
      selectedAdmin,
      updateLoadedState,
      setDisplayText: currentSelection.setDisplayText,
      setSelection: currentSelection.setSelection,
    };

    await maps.loadAdminData(map, state);
  };

  // user interaction with map triggered
  useEffect(() => {
    if (
      currentMap.mapLoaded &&
      currentSelection.gidRef &&
      currentSelection.gid
    ) {
      adminLevelRef.current = currentSelection.adminLevel;
      updateSelectedGidState(currentSelection.adminLevel, currentSelection.gid);
    }
  }, [currentMap.mapLoaded, currentSelection.gid, currentSelection.adminLevel]);

  useEffect(() => {
    currentMap.mapRef.current?.on("mousemove", (e) => {
      setLngLat(e.lngLat);
    });
  }, [currentMap.mapRef.current]);

  return (
    <div className="relative">
      <div id="map" ref={currentMap.map} className="h-screen w-full -mt-20" />
      {currentMap.mapRef?.current && (
        <>
          <Scale />
          <Coordinates />
        </>
      )}
    </div>
  );
};

export default Mapbox;
