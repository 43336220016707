import { format } from "d3-format";

const formatInteger = { format }.format(",.0f");
const formatDecimal = { format }.format(",.2f");

export default function formatNumber(value, unit) {
  if (unit === "Mha") {
    if (value > 0.9 && value < 10) {
      return formatDecimal(value);
    }

    return value;
  }

  return value > 1 ? formatInteger(value) : value;
}
